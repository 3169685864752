import React, {useState, useContext, Fragment, useEffect} from 'react';
import { useHistory, Link } from "react-router-dom";
import Header from './Header';
import { YearPicker, MonthPicker, DayPicker } from 'react-dropdown-date';
import ReactFlagsSelect from 'react-flags-select';
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import { GlobalContext } from '../context';
import { Apis } from '../utils/Apis';
import { isEmpty } from '../utils/AppConst';
import FormValidation from '../components/FormValidation';
import Loading from './Loading';

import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import loadjs from "loadjs";
import Footer from './Footer';
import Alert from './ui/Alert';

import { updateProfileSchema } from '../utils/Yup';
import { DisplayFormikState } from '../utils/DisplayFormikState';
import ApiErrors from './ApiErrors';

const UpdateProfile = () => {

  const { authenticated, username, token, loading, setLoading,country,setCountry} = useContext(GlobalContext);

  const history = useHistory();

  useEffect(() => {

    if(authenticated !== "true") history.push('/login');

    loadjs([
        '/assets/js/jquery-3.3.1.min.js',
        '/assets/js/popper.min.js', 
        '/assets/vendor/bootstrap-4.4.1/js/bootstrap.min.js', 
        '/assets/vendor/swiper/js/swiper.min.js', 
        '/assets/js/main.js', 
    ]);

    // get list of source countries
    preLoad();
    getProfile();

    
  },[])
  




  const preLoad = async () => {

    setLoading(true);

    await Apis.get(`ui/countries`)
    .then(data => {
      // setLoading(false)
      if (data.status == "SUCCESS") {
        let source_countries = data.data.source_countries
        let allowed = [];
        source_countries.forEach(function (c, i) {
          allowed = allowed.concat(`${(c.iso_code).toUpperCase()}`);
        });
        setCountryOptions(allowed);
      } else {
        setErrors([{message: data.message}]);
      }
    })
    .catch(e => {
      setErrors([{message: e.message}]);
    })
    .finally(() => {
      // setLoading(false);
    });

  }


  const getProfile =  async() => {

      setLoading(true);
      let data = JSON.stringify({ username : username, token : token });
      await Apis.post(`profile`, data)
      .then(data => {
        if (data.status == "SUCCESS") {
            setProfile(data.data)
            setCountry(data.data.country)
            console.log(data.data)
        }
        else {
            if ( typeof data.errors !== 'undefined' && typeof data.errors !== null) console.log(JSON.stringify(data.errors));
          else console.log(data.message);
        }
      })
      .catch(e => setLoading(false) )
      .finally(() =>  setLoading(false) );
    }

  const [countryOptions, setCountryOptions] = useState([]);
  const [profile, setProfile] = useState([]);

  // errors & validations
  const [errors, setErrors] = useState([]);
  const [apiErrors, setApiErrors] = useState([]);

  const getMonth = (num) => {
    let names = [ "January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December" ];
    num = (Number(num).toString() !== num) ? num.substring(1) : num;
    return (num > 0 && num < 13) ? names[num-1] : null;
  }

  const removeZero = (num) => {
    return (Number(num).toString() !== num) ? num.substring(1) : num;
  }

  const initialValues = {
    firstName: (profile && profile.firstname) ? profile.firstname : '',
    middleName: (profile && profile.middlename) ? profile.middlename : '',
    lastName: (profile && profile.lastname) ? profile.lastname : '',
    email: '',
    birthDay: (profile && profile.dob) ? (profile.dob).split('-')[2] : '',
    birthMonth: (profile && profile.dob) ? removeZero((profile.dob).split('-')[1])-1 : '',
    birthYear: (profile && profile.dob) ? (profile.dob).split('-')[0] : '',
    nationality: (profile && profile.nationality) ? profile.nationality : '',
    gender: (profile && profile.gender) ? profile.gender : '',
    purpose: (profile && profile.default_transfer_purpose) ? profile.default_transfer_purpose : '',
    mobile: (profile && profile.mobile) ? profile.mobile : '',
    postCode: (profile && profile.postcode) ? profile.postcode : '',
    addressLine1: (profile && profile.address1) ? profile.address1 : '',
    addressLine2: (profile && profile.address2) ? profile.address2 : '',
    city: (profile && profile.city) ? profile.city : '',
    state: (profile && profile.state) ? profile.state : '',
    idType: (profile && profile.documents) ? profile.documents[0].id_type : '',
    idDetails: (profile && profile.documents) ? profile.documents[0].id_details : '',
    issuePlace: (profile && profile.documents) ? profile.documents[0].id_issue_place : '',
    issueCountry: (profile && profile.documents) ? profile.documents[0].id_issue_country : '',
    idDay: (profile && profile.documents) ? removeZero((profile.documents[0].id_expiry).split('-')[2]) : '',
    idMonth: (profile && profile.documents) ? removeZero((profile.documents[0].id_expiry).split('-')[1])-1 : '',
    idYear: (profile && profile.documents) ? (profile.documents[0].id_expiry).split('-')[0] : '',
    id1Scan1: '',
    id1Scan2: '',
    id2Scan1: ''
  }

  const generateAPIDate = (day,month,year) => {
    day = ( (day).toString().length == 1) ? '0' + day : day;
    month = !isEmpty(month) ? eval(month) + 1 : '';
    if ( (month).toString().length == 1) month = '0' + month;
    return [year, month, day].join('-');
  }

  const submitForm = async (values, {setSubmitting, setErrors, setStatus, resetForm}) => {

    let formValues = values;

    setLoading(true);

    let data = {
      username : username,
      token : token,
      fname : formValues.firstName,
      mname : formValues.middleName,
      lname : formValues.lastName,
      dob : generateAPIDate(formValues.birthDay, formValues.birthMonth, formValues.birthYear),
      nationality : formValues.nationality,
      gender : formValues.gender,
      address1 : formValues.addressLine1,
      address2 : formValues.addressLine2,
      city : formValues.city,
      state : formValues.state,
      postcode : formValues.postCode,
      purpose : formValues.purpose,
      mobile : formValues.mobile,
      id1_type : formValues.idType,
      id1_details : formValues.idDetails,
      id1_issue_place : formValues.issuePlace,
      id1_issue_country : formValues.issueCountry,
      id1_expiry : generateAPIDate(formValues.idDay, formValues.idMonth, formValues.idYear),
      id1_scan : formValues.id1Scan1,
      id1_scan2 : formValues.id1Scan2,
    };

    console.log(data)

    if (country == 'South Africa'){
      data.id2_type = 'Selfie';
      data.id2_scan = formValues.id2Scan1;
    }
    
    data = JSON.stringify(data);

    await Apis.post(`profile/update`, data)
    .then(data => {
      if (data.status == "SUCCESS") {
        
        history.push({
           pathname: '/profile',
           flash: `Profile Successfully Updated`,
        });
        
      } else {
        if ( typeof data.errors !== 'undefined' && typeof data.errors !== null)  setApiErrors([data.errors]);
        else setApiErrors([data.message]);
      }

    })
    .catch((e) =>  setApiErrors([{message: e.message}]) )
    .finally(() => setLoading(false) );

  }

  return (
    <Fragment>

    <Loading active={loading} />

    <Header title='Update Profile' hasBack hasLogout link='/profile' />
    {/* <Header withMenu /> */}

    

    <div className="row no-gutters login-row">
            <div className="col px-3">

                <ApiErrors errors={apiErrors} />

                <Formik
                  initialValues={initialValues}
                  enableReinitialize={true}
                  validationSchema={updateProfileSchema(country)}
                  onSubmit={submitForm}
                >
                  {(formik) => {
                    const {
                      values,
                      handleChange,
                      handleSubmit,
                      errors,
                      touched,
                      handleBlur,
                      isValid,
                      dirty,
                      setFieldValue
                    } = formik;
                    return (
                      
                
                <form onSubmit={handleSubmit} className="form-signin mt-3 " autoComplete="off">
                
                    {/* <h6 className="subtitle">Personal Information</h6> */}

                    <div className="form-group">
                        <label>First Name <span className='text-danger'>*</span></label>
                        <input type="text" 
                          name="firstName"
                          value={values.firstName} 
                          onChange={handleChange} 
                          onBlur={handleBlur}
                          className={ `form-control ${ errors.firstName && touched.firstName ? "is-invalid" : ( touched.firstName ? "is-valid" : null ) }`  } 
                        />
                        {errors.firstName && touched.firstName && ( <span className="form-text text-muted small text-danger">{errors.firstName}</span> )}
                    </div>

                    <div className="form-group">
                    <label>Middle Name(s)</label>
                        <input type="text" 
                          name="middleName"
                          value={values.middleName} 
                          onChange={handleChange} 
                          onBlur={handleBlur}
                          className={ `form-control ${ errors.middleName && touched.lastName ? "is-invalid" : ( touched.middleName ? "is-valid" : null ) }`  } 
                        />
                        {errors.middleName && touched.middleName && ( <span className="form-text text-muted small text-danger">{errors.middleName}</span> )}
                    </div>

                    <div className="form-group">
                    <label>Last Name <span className='text-danger'>*</span></label>
                        <input type="text" 
                          name="lastName"
                          value={values.lastName} 
                          onChange={handleChange} 
                          onBlur={handleBlur}
                          className={ `form-control ${ errors.lastName && touched.lastName ? "is-invalid" : ( touched.lastName ? "is-valid" : null ) }`  } 
                        />
                        {errors.lastName && touched.lastName && ( <span className="form-text text-muted small text-danger">{errors.lastName}</span> )}
                    </div>

                    

                    <div className="form-group">
                    <label>Date of Birth <span className='text-danger'>*</span></label>

                      <div className="row">
                          <div className="col-4">
                          <DayPicker
                              // name="dateYear"
                              defaultValue={'Day'}
                              year={values.birthYear}    // mandatory
                              month={values.birthMonth}  // mandatory
                              endYearGiven              // mandatory if end={} is given in YearPicker
                              required={false}           // default is false
                              disabled={false}           // default is false
                              value={values.birthDay}    // mandatory
                              onChange={(birthDay) => setFieldValue('birthDay', birthDay)}
                              // onChange = {handleChange}
                              onBlur={handleBlur}
                              // onChange={(e) => setFieldValue('day', e,true)}
                              id='birthDay'
                              name='birthDay'
                             classes={ `date-select form-control ${ errors.birthDay ? "is-invalid" : (values.birthDay && "is-valid") }`  }
                            //  classes={ `date-select form-control ${ errors.birthDay ? "is-invalid" : (values.birthDay && "is-valid") }`  }
                              optionClasses={'option classes'}
                          />
                          
                          </div>
                          <div className="col-4">
                          <MonthPicker
                          // name="dateMonth"
                              defaultValue={'Month'}
                              // numeric                   // to get months as numbers
                              // short                     // default is full name
                              // caps                      // default is Titlecase
                              endYearGiven              // mandatory if end={} is given in YearPicker
                              year={values.birthYear}    // mandatory
                              required={false}           // default is false
                              disabled={false}           // default is false
                              value={values.birthMonth}  // mandatory
                              // onChange={(month) => {    // mandatory
                              // setDateMonth(month)
                              // }}
                              // onChange = {handleChange}
                              onChange={(birthMonth) => setFieldValue('birthMonth', birthMonth)}
                              onBlur={handleBlur}
                              id={'birthMonth'}
                              name={'birthMonth'}
                              // classes={'date-select'}
                              // classes={ `date-select form-control ${ errors.birthMonth ? "is-invalid" : "is-valid" }`  }
                              classes={ `date-select form-control ${ errors.birthMonth ? "is-invalid" : (values.birthMonth && "is-valid") }`  }
                              optionClasses={'option classes'}
                          />
                          </div>
                          <div className="col-4">
                          <YearPicker
                              // name="dateYear"
                              defaultValue={'Year'}
                              // start={ (new Date().getFullYear()) - 16 }                // default is 1900
                              end={ (new Date().getFullYear()) - 18 }                  // default is current year
                              reverse                     // default is ASCENDING
                              required={false}             // default is false
                              disabled={false}             // default is false
                              value={values.birthYear}     // mandatory
                              // onChange={(values.year) => {       // mandatory
                              // setDateYear(year);
                              // }}
                              // onChange={handleChange}
                              onChange={(birthYear) => setFieldValue('birthYear', birthYear)}
                              onBlur={handleBlur}
                              id={'birthYear'}
                              name={'birthYear'}
                              // classes={'date-select'}
                              classes={ `date-select form-control ${ errors.birthYear ? "is-invalid" : (values.birthYear && "is-valid") }`  }
                              optionClasses={'option classes'}
                          />
                          </div>
                      </div>

                      { ( errors.birthDay || errors.birthMonth || errors.birthYear ) && ( <span className="form-text text-muted small text-danger">Please select a valid date of birth.</span> )}
                    </div>

                    <div className="form-group">
                        <label>Nationality <span className='text-danger'>*</span></label>
                        <ReactFlagsSelect
                            name="nationality"
                            id="nationality"
                            selected={values.nationality}
                            onSelect={code => setFieldValue('nationality', code,true)}
                            // onSelect={handleChange}
                            onBlur={handleBlur}
                            // countries={countryOptions}
                            // countries={["GB", "ZA"]}
                            placeholder="Select Nationality"
                            selectedSize={10}
                            optionsSize={14}
                            // className="select-flags"
                            className={ `select-flags ${ errors.nationality ? "is-invalid" : "is-valid" }`  } 
                        />
                        {errors.nationality && touched.nationality && ( <span className="form-text text-muted small text-danger pl-3">{errors.nationality}</span> )}
                    </div>

                    <div className="form-group">
                        <label>Gender <span className='text-danger'>*</span></label>
                        <select 
                        name="gender"
                        value={values.gender}
                        onChange={handleChange}
                        className={ `form-control select-input ${ errors.gender && touched.gender ? "is-invalid" : ( touched.gender ? "is-valid" : null ) }`  } 
                        onBlur={handleBlur}
                        >
                            <option value="">Select Gender</option>
                            <option value="Male">Male</option>
                            <option value="Female">Female</option>
                            <option value="Unspecified">Unspecified</option>
                        </select>
                        {errors.gender && touched.gender && ( <span className="form-text text-muted small text-danger pl-3">{errors.gender}</span> )}
                    </div>

                    <div className="form-group">
                        <label>Purpose of Remittance <span className='text-danger'>*</span></label>
                        <select 
                        name="purpose"
                        value={values.purpose}
                        onChange={handleChange}
                        className={ `form-control select-input ${ errors.purpose && touched.purpose ? "is-invalid" : ( touched.purpose ? "is-valid" : null ) }`  } 
                        onBlur={handleBlur}
                        >
                            <option value=''>Select Purpose of Remittance</option>
                            <option value="400 Reversal">400 Reversal</option>
                            <option value="401 Gifts">401 Gifts</option>
                            <option value="416 Migrant Worker Remittances (excluding Compensation)">416 Migrant Worker Remittances (excluding Compensation)</option>
                            <option value="417 (Foreign National Contract Worker Remittances excluding Compensation)">417 (Foreign National Contract Worker Remittances excluding Compensation)</option>
                            <option value="Other">Other</option>
                        </select>
                        {errors.purpose && touched.purpose && ( <span className="form-text text-muted small text-danger pl-3">{errors.purpose}</span> )}
                    </div>

                    {/* <br/>
                    <h6 className="subtitle">Contact Information</h6> */}

                    <div className="form-group">
                        <label>Mobile Number <span className='text-danger'>*</span></label>
                        <PhoneInput
                            name="mobile"
                            // country={ !isEmpty(values.country) ? (values.country).toLowerCase() : 'gb'}
                            value={values.mobile}
                            // onChange={handleChange}
                            onChange={(mobile) => setFieldValue('mobile', mobile)}
                            onBlur={handleBlur}
                            // onChange={phone => setPhone(phone)}
                            placeholder=''
                            // containerClass='phone-input'
                            containerClass={ `phone-input ${ errors.mobile ? "is-invalid" : "is-valid" }`  } 
                            autoFormat={false}
                        />
                        {errors.mobile && ( <span className="form-text text-muted small text-danger pl-3">{errors.mobile}</span> )}
                    </div>

                    
                    <div className="form-group">
                        <label>Post Code <span className='text-danger'>*</span></label>
                        <input type="text" 
                          name="postCode"
                          className={ `form-control ${ errors.postCode && touched.postCode ? "is-invalid" : ( touched.postCode ? "is-valid" : null ) }`  } 
                          value={values.postCode} 
                          onChange={handleChange} 
                          onBlur={handleBlur}
                        />
                        {errors.postCode && touched.postCode && ( <span className="form-text text-muted small text-danger">{errors.postCode}</span> )}
                    </div>


                    
                    <div className="form-group">
                        <label>Address Line 1 <span className='text-danger'>*</span></label>
                        <input type="text" 
                          name="addressLine1"
                          className={ `form-control ${ errors.addressLine1 && touched.addressLine1 ? "is-invalid" : ( touched.addressLine1 ? "is-valid" : null ) }`  } 
                          value={values.addressLine1} 
                          onChange={handleChange} 
                          onBlur={handleBlur}
                        />
                        {errors.addressLine1 && touched.addressLine1 && ( <span className="form-text text-muted small text-danger">{errors.addressLine1}</span> )}
                    </div>


                    
                    <div className="form-group">
                        <label>Address Line 2</label>
                        <input type="text" 
                          name="addressLine2"
                          className={ `form-control ${ errors.addressLine2 && touched.addressLine2 ? "is-invalid" : ( touched.addressLine2 ? "is-valid" : null ) }`  } 
                          value={values.addressLine2} 
                          onChange={handleChange} 
                          onBlur={handleBlur}
                        />
                        {errors.addressLine2 && touched.addressLine2 && ( <span className="form-text text-muted small text-danger">{errors.addressLine2}</span> )}
                    </div>

                    
                    <div className="form-group">
                        <label>City <span className='text-danger'>*</span></label>
                        <input type="text" 
                          name="city"
                          className={ `form-control ${ errors.city && touched.city ? "is-invalid" : ( touched.city ? "is-valid" : null ) }`  } 
                          value={values.city} 
                          onChange={handleChange} 
                          onBlur={handleBlur}
                        />
                        {errors.city && touched.city && ( <span className="form-text text-muted small text-danger">{errors.city}</span> )}
                    </div>


                    
                    <div className="form-group">
                        <label>State/Province <span className='text-danger'>*</span></label>
                        <input type="text" 
                          name="state"
                          className={ `form-control ${ errors.state && touched.state ? "is-invalid" : ( touched.state ? "is-valid" : null ) }`  } 
                          value={values.state} 
                          onChange={handleChange} 
                          onBlur={handleBlur}
                        />
                        {errors.state && touched.state && ( <span className="form-text text-muted small text-danger">{errors.state}</span> )}
                    </div>
                    

                    <br/>
                    <h6 className="subtitle text-center ">Photo ID</h6>


                    <div className="form-group">
                        <label>ID Type <span className='text-danger'>*</span></label>
                        <select 
                        // className="form-control"
                        name="idType"
                        value={values.idType}
                        onChange={handleChange}
                        className={ `form-control select-input ${ errors.idType && touched.idType ? "is-invalid" : ( touched.idType ? "is-valid" : null ) }`  } 
                        onBlur={handleBlur}
                        >
                            <option value="">Select ID Type</option>
                            <option value="PASSPORT">PASSPORT</option>
                            <option value="DRIVER'S LICENSE">DRIVER'S LICENSE</option>
                            <option value="NATIONAL ID">NATIONAL ID</option>
                        </select>
                        {errors.idType && touched.idType && ( <span className="form-text text-muted small text-danger">{errors.idType}</span> )}
                    </div>

                    <div className="form-group">
                        <label>ID Number/Details <span className='text-danger'>*</span></label>
                          <input type="text"  
                            name="idDetails"
                            value={values.idDetails}
                            onChange={handleChange}
                            className={ `form-control ${ errors.idDetails && touched.idDetails ? "is-invalid" : ( touched.idDetails ? "is-valid" : null ) }`  } 
                            onBlur={handleBlur}
                            autoComplete="off"
                        />
                        {errors.idDetails && touched.idDetails && ( <span className="form-text text-muted small text-danger">{errors.idDetails}</span> )}
                    </div>

                    

                   

                    <div className="form-group">
                        <label>ID Issue Place <span className='text-danger'>*</span></label>
                        <input type="text" 
                          name="issuePlace"
                          className={ `form-control ${ errors.issuePlace && touched.issuePlace ? "is-invalid" : ( touched.issuePlace ? "is-valid" : null ) }`  } 
                          value={values.issuePlace} 
                          onChange={handleChange} 
                          onBlur={handleBlur}
                        />
                        {errors.issuePlace && touched.issuePlace && ( <span className="form-text text-muted small text-danger">{errors.issuePlace}</span> )}
                    </div>

                    <div className="form-group">
                        <label>ID Issue Country <span className='text-danger'>*</span></label>
                        <ReactFlagsSelect
                            name="issueCountry"
                            selected={values.issueCountry}
                            onSelect={code => setFieldValue('issueCountry', code,true)}
                            // onSelect={handleChange}
                            onBlur={handleBlur}
                            // countries={countryOptions}
                            // countries={["GB", "ZA"]}
                            placeholder="Select Issue Country"
                            selectedSize={10}
                            optionsSize={14}
                            // className="select-flags"
                            className={ `select-flags ${ errors.issueCountry && touched.issueCountry ? "is-invalid" : ( touched.issueCountry ? "is-valid" : null ) }`  } 
                        />
                        {errors.issueCountry && touched.issueCountry && ( <span className="form-text text-muted small text-danger pl-3">{errors.issueCountry}</span> )}
                    </div>

                    <div className="form-group">
                      <label>ID Expiry Date <span className='text-danger'>*</span></label>

                      <div className="row">
                          <div className="col-4">
                          <DayPicker
                              // name="dateYear"
                              defaultValue={'Day'}
                              year={values.idYear}    // mandatory
                              month={values.idMonth}  // mandatory
                              endYearGiven              // mandatory if end={} is given in YearPicker
                              required={false}           // default is false
                              disabled={false}           // default is false
                              value={values.idDay}    // mandatory
                              onChange={(idDay) => setFieldValue('idDay', idDay)}
                              // onChange = {handleChange}
                              onBlur={handleBlur}
                              // onChange={(e) => setFieldValue('day', e,true)}
                              id={'idDay'}
                              name={'idDay'}
                              // classes={'date-select'}
                              classes={ `date-select form-control ${ errors.idDay ? "is-invalid" : (values.idDay && "is-valid") }`  }
                              optionClasses={'option classes'}
                          />
                          </div>
                          <div className="col-4">
                          <MonthPicker
                          // name="dateMonth"
                              defaultValue={'Month'}
                              // numeric                   // to get months as numbers
                              // short                     // default is full name
                              // caps                      // default is Titlecase
                              endYearGiven              // mandatory if end={} is given in YearPicker
                              year={values.idYear}    // mandatory
                              required={false}           // default is false
                              disabled={false}           // default is false
                              value={values.idMonth}  // mandatory
                              // onChange={(month) => {    // mandatory
                              // setDateMonth(month)
                              // }}
                              // onChange = {handleChange}
                              onChange={(idMonth) => setFieldValue('idMonth', idMonth)}
                              onBlur={handleBlur}
                              id={'idMonth'}
                              name={'idMonth'}
                              // classes={'date-select'}
                              classes={ `date-select form-control ${ errors.idMonth ? "is-invalid" : (values.idMonth && "is-valid") }`  }
                              optionClasses={'option classes'}
                          />
                          </div>
                          <div className="col-4">
                          <YearPicker
                              // name="dateYear"
                              defaultValue={'Year'}
                              // start={ (new Date().getFullYear()) - 16 }                // default is 1900
                              end={ (new Date().getFullYear()) + 50 }                  // default is current year
                              reverse                     // default is ASCENDING
                              required={false}             // default is false
                              disabled={false}             // default is false
                              value={values.idYear}     // mandatory
                              // onChange={(values.year) => {       // mandatory
                              // setDateYear(year);
                              // }}
                              // onChange={handleChange}
                              onChange={(idYear) => setFieldValue('idYear', idYear)}
                              onBlur={handleBlur}
                              id={'idYear'}
                              name={'idYear'}
                              // classes={'date-select'}
                              classes={ `date-select form-control ${ errors.idYear ? "is-invalid" : (values.idYear && "is-valid") }`  }
                              optionClasses={'option classes'}
                          />
                          </div>
                      </div>

                      { ( errors.idDay  || errors.idMonth  || errors.idYear ) && ( <span className="form-text text-muted small text-danger">Please select a valid expiry date.</span> )}

                    </div>

                     <div className="form-group">
                        <label>ID Scan 1 (Front) <span className='text-danger'>*</span></label>
                        <input type="file" 
                        name="id1Scan1"
                        value={values.id1Scan1} 
                        onChange={handleChange} 
                        onBlur={handleBlur}
                        className={ `form-control ${ errors.id1Scan1 && touched.id1Scan1 ? "is-invalid" : ( touched.id1Scan1 ? "is-valid" : null ) }`  } 
                        />
                        {errors.id1Scan1 && touched.id1Scan1 && ( <span className="form-text text-muted small text-danger">{errors.id1Scan1}</span> )}
                    </div>

                    <div className="form-group">
                      <label>ID Scan 2 (Back)</label>
                      <input type="file" 
                      name="id1Scan2"
                      value={values.id1Scan2} 
                      onChange={handleChange} 
                      onBlur={handleBlur}
                      className={ `form-control ${ errors.id1Scan2 && touched.id1Scan2 ? "is-invalid" : ( touched.id1Scan2 ? "is-valid" : null ) }`  } 
                      />
                      {errors.id1Scan2 && touched.id1Scan2 && ( <span className="form-text text-muted small text-danger">{errors.id1Scan2}</span> )}
                    </div>

                    { country === 'South Africa' && <>
                    <br/>
                    <h6 className="subtitle text-center">Selfie</h6>
                    <br/>

                    <div className="form-group">
                      <label>Selfie Photo <span className='text-danger'>*</span></label>
                      <input type="file" 
                      name="id2Scan1"
                      value={values.id2Scan1} 
                      onChange={handleChange} 
                      onBlur={handleBlur}
                      className={ `form-control ${ errors.id2Scan1 && touched.id2Scan1 ? "is-invalid" : ( touched.id2Scan1 ? "is-valid" : null ) }`  } 
                      />
                      {errors.id2Scan1 && touched.id2Scan1 && ( <span className="form-text text-muted small text-danger">{errors.id2Scan1}</span> )}
                    </div>
                    </> }
                    <br/>
                    {/* <DisplayFormikState {...formik} /> */}
                    <br/>



                      <div className="row mx-0">
                          <div className="col">
                              {/* <p align="center" className="terms">By creating an account you accept our Terms of Service</p> */}
                              <button 
                              // className="btn btn-default btn-lg btn-rounded shadow btn-block" 
                              className={ `btn btn-default btn-lg btn-rounded shadow btn-block ${ dirty && isValid ? " btn-default " : "btn-secondary" }` }
                              disabled={!(dirty && isValid)}
                              // onClick={() => submitForm()}
                              >Save Changes</button>
                          </div>
                      </div>
                      
                  
                </form>    
                
                );
                }}

              </Formik>

            </div>
        </div>

        <br/>

        
              <Footer page='Profile'/>
        
     
    </Fragment>
    
  );
};

export default UpdateProfile;