import React, {Fragment, useContext} from 'react';

import { Route, Switch } from 'react-router-dom';

import { GlobalContext } from './context';

// Components
import Dashboard from './components/Dashboard';
import Login from './components/Login';
import ForgotPassword from './components/ForgotPassword';
import ResetPassword from './components/ResetPassword';
import ChangePassword from './components/ChangePassword';

import PageNotFound from './components/PageNotFound';
import Loading from './components/Loading';
import Sidebar from './components/Sidebar';
import Modals from './components/Modals';
import Beneficiaries from './components/Beneficiaries';
import SingleBeneficiary from './components/SingleBeneficiary';
import AddBeneficiary from './components/AddBeneficiary';
import UpdateBeneficiary from './components/UpdateBeneficiary';
import Profile from './components/Profile';
import UpdateProfile from './components/UpdateProfile';
import SendMoney from './components/SendMoney';
import Transactions from './components/Transactions';
import SingleTransaction from './components/SingleTransaction';
import Register from './components/Register';
import VerifyRegistration from './components/VerifyRegistration';

function App() {

  const { loading } = useContext(GlobalContext);

  return (  
    <Fragment>
  
    <Loading active={loading} />

    <Sidebar/>

    <div className="wrapper">

    <Switch>
      <Route exact path="/" component={Dashboard} />
      <Route exact path="/beneficiaries" component={Beneficiaries} />
      <Route exact path="/beneficiaries/new" component={AddBeneficiary} />
      <Route exact path="/beneficiaries/update/:slug" component={UpdateBeneficiary} />
      <Route exact path="/beneficiaries/:slug" component={SingleBeneficiary} />
      <Route exact path="/transactions" component={Transactions} />
      <Route exact path="/profile" component={Profile} />
      <Route exact path="/profile/update" component={UpdateProfile} />
      <Route exact path="/send-money" component={SendMoney} />
      <Route exact path="/transactions/:slug" component={SingleTransaction} />
        
      {/* <Route path="/old-match">
        <Redirect to="/will-match" />
      </Route> */}
      <Route exact path="/login" component={Login} />
      <Route exact path="/register" component={Register} />
      <Route exact path="/verify-registration" component={VerifyRegistration} />
      <Route exact path="/forgot-password" component={ForgotPassword} />
      <Route exact path="/reset-password" component={ResetPassword} />
      <Route exact path="/change-password" component={ChangePassword} />
      
      {/* <Route exact path="/blog/category/:slug" component={Archive} /> */}
      
      {/* <Route exact path="/blog/:slug" component={Single} /> */}
        

      <Route path="*">
        <PageNotFound/>
      </Route>
    </Switch>

    </div>

    <Modals/>

    </Fragment>
  );
  
}

export default App;
