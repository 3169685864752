import React,{Fragment,useState, useEffect, useContext} from 'react';
import { useHistory, Link } from "react-router-dom";
import { GlobalContext } from '../context';
import { Apis } from '../utils/Apis';
// import { AppConst, capitalize } from '../utils/AppConst';
import Footer from './Footer';
import Header from './Header';
import Loading from './Loading';
import PulseLoader from "react-spinners/PulseLoader";
import TransactionAmount from './TransactionAmount';
import loadjs from "loadjs";
import Status from './ui/Status';

const Dashboard = () => {

    const {loading, setLoading, authenticated, username, token, country, setCountry, status,setStatus} = useContext(GlobalContext)

    const [transactions, setTransactions] = useState([]);
    const [recentTransactions, setRecentTransactions] = useState([]);
    const [beneficiaries, setBeneficiaries] = useState([]);
    const [profile, setProfile] = useState([]);
    const [rates, setRates] = useState([]);

    const history = useHistory();

    useEffect(() => {

        if(authenticated === "false") {
            history.push('/login');
        }

        loadjs([
          '/assets/js/jquery-3.3.1.min.js',
          '/assets/js/popper.min.js', 
          '/assets/vendor/bootstrap-4.4.1/js/bootstrap.min.js', 
          '/assets/vendor/swiper/js/swiper.min.js', 
          '/assets/js/main.js', 
        ]);

        getProfile()

        getBeneficiaries()
        getRates()
        // getRecentTransactions()
        getTransactions()
        
    }, [])

    const getProfile =  async() => {

      setLoading(true);
      let data = JSON.stringify({ username : username, token : token });
      await Apis.post(`profile`, data)
      .then(data => {
        setLoading(false)
        if (data.status == "SUCCESS") {
            setProfile(data.data)
            setCountry(data.data.country)
            setStatus(JSON.stringify({status:data.data.status,type:data.data.type}))
        }
        else {
            if ( typeof data.errors !== 'undefined' && typeof data.errors !== null) console.log(JSON.stringify(data.errors));
          else console.log(data.message);
        }
      })
      .catch(e => setLoading(false) )
      .finally(() =>  setLoading(false) );
    }

    const getRates =  async() => {
      setLoading(true);
      await Apis.get(`ui/rates`)
      .then(data => {
        setLoading(false)
        if (data.status == "SUCCESS") setRates(data.data)
        else console.log(data.message);
      })
      .catch(e => setLoading(false) )
      .finally(() =>  setLoading(false) );
    }

    const getBeneficiaries =  async() => {
      setLoading(true);
      let data = JSON.stringify({ username : username, token : token });
      await Apis.post(`beneficiaries`, data)
      .then(data => {
        setLoading(false)
        if (data.status == "SUCCESS") setBeneficiaries(data.data)
        else console.log(data.message);
      })
      .catch(e => setLoading(false) )
      .finally(() =>  setLoading(false) );
    }

    const getTransactions =  async() => {
      setLoading(true);
      let data = JSON.stringify({ username : username, token : token });
      await Apis.post(`transactions`, data)
      .then(data => {
        setLoading(false)
        if (data.status == "SUCCESS") setTransactions(data.data)
        else console.log(data.message);
      })
      .catch(e => setLoading(false) )
      .finally(() =>  setLoading(false) );
    }

    const getRecentTransactions =  async() => {
      setLoading(true);
      let data = JSON.stringify({ username : username, token : token });
      await Apis.post(`transactions/recent`, data)
      .then(data => {
        setLoading(false)
        if (data.status == "SUCCESS") setRecentTransactions(data.data)
        else console.log(data.message);
      })
      .catch(e => setLoading(false) )
      .finally(() =>  setLoading(false) );
    }



  return (
    <Fragment >

    <Loading active={loading}/>

      <Header hasLogout />

      <div className="container">
            <div className="card bg-template-custom shadow mt-4 h-190">
                <div className="card-body">
                    <div className="row">
                        <div className="col-auto">
                            <figure className="avatar avatar-60"><img src="/assets/img/avatar.png" alt=""/></figure>
                        </div>
                        <div className="col pl-0 align-self-center">
                            <h5 className="mb-1">{profile.firstname} {profile.lastname}</h5>
                            <p className="text-mute small">{profile.country}
                                <small className="float-right">Member Since: {profile.creation_date}</small>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="container top-100">
            <div className="card mb-4 shadow">
                <div className="card-body border-bottom">
                    <div className="row">
                        <div className="col text-center">
                            <p>
                            {rates.length !== 0 ? rates.USD_TO_ZAR_INVERSE_ROUNDED : (<PulseLoader color="#3D376B" loading={true} size={10} />)}

                            <br/><small className="text-mute">ZAR</small></p>
                        </div>
                        <div className="col text-center">
                            <p>1.00<br/><small className="text-mute">USD</small></p>
                        </div>
                        <div className="col text-center">
                            <p>
                            {rates.length !== 0 ? rates.GBP_TO_USD_ROUNDED : (<PulseLoader color="#3D376B" loading={true} size={10} />)}
                            <br/><small className="text-mute">GBP</small></p>
                        </div>
                    </div>
                </div>
                <>
                <div className="card-footer bg-none">
                    <Link to='/send-money' className="btn btn-default shadow  btn-rounded btn-block btn-lg ">Send Money</Link>
                </div>
                </>
            </div>
        </div>
        <br/>
        <div className="container">

            {/* <Status /> */}

            {/* <div className="card shadow border-0 mb-3">
                <div className="card-body">
                    <div className="row">
                        <div className="col">
                            <p className="text-mute small text-secondary ">Sending limit remaining</p>
                        </div>
                        <div className="col-auto">
                            <h5 className="font-weight-normal ">R 500.00 </h5>
                        </div>
                    </div>
                    <div className="progress h-4">
                        <div className="progress-bar bg-danger" role="progressbar" style={{ width:'65%' }} aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
                    </div>
                </div>
            </div> */}
            { profile.status == 'blocked' ? null : <>
            <h4 className="mt-5 mb-2" align="center"><span className="font-weight-light">Your </span>Beneficiaries</h4>
            <div className="row benef-swiper">
                <div className="swiper-container icon-slide mb-4">
                    <div className="swiper-wrapper">

                        {beneficiaries.length === 0 ? (
                            <center>
                            <PulseLoader color="#3D376B" loading={true} size={15} margin={10} />
                            </center>
                            ) :
                                beneficiaries.slice(0, 4).map((benef, index) => {
                                    return(
                                        <Link key={index} to={"/beneficiaries/"+benef.beneficiary_id} className="swiper-slide text-center">
                                            <div className="avatar avatar-60 no-shadow border-0">
                                                <div className="overlay benef-circle"></div>
                                            </div>
                                            <p className="small mt-2">{benef.name}</p>
                                        </Link>
                                    )
                                })
                        
                        }
                        
                        {/* <Link to="/beneficiaries" className="swiper-slide text-center">
                            <p className="small mt-2" style={{paddingTop: 20, fontWeight: 'bold'}} >See All</p>
                        </Link> */}
                    </div>
                    <div className="swiper-pagination"></div>
                </div>
            </div>
            </>
            }

            
            
        </div>
        
       
        <div className="container">
            
            { profile.status == 'blocked' ? null : <>
            <h4 className="mt-5  mb-2" align="center"><span className="font-weight-light">Recent </span>Transactions</h4>
            {/* {recentTransactions.length === 0 ? ( */}
            {transactions.length === 0 ? (
                <center>
                <PulseLoader color="#3D376B" loading={true} size={15} margin={10} />
                </center>
                ) :
                transactions.slice(0, 3).map((trans, index) => {
                return(
                    <Link 
                    key={index}
                    to={`/transactions/${trans.trans_ref}`} 
                    >
                    <div  className="card shadow border-0 mb-3">
                        <div className="card-body">
                            <div className="row align-items-center">
                                <div className="col-auto align-self-center">
                                    <h6 className="font-weight-normal mb-1">{trans.benef_name}</h6>
                                    <p className="text-mute small text-secondary">{trans.trans_ref}</p>
                                </div>
                                <div className="col text-right">
                                    <h6 className="font-weight-normal mb-1"><TransactionAmount id={trans.trans_ref} /></h6>
                                    <p className="text-mute small text-secondary">Sent: {trans.creation_date}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    </Link>
                )
            })}
            
          
           <center>
           <Link className="btn btn-rounded shadow btn-sm btn-default" to="/transactions" >See All</Link>
           </center>
            </>
            }

        </div>

        <Footer page='Dashboard' />

    </Fragment>
  );
};

export default Dashboard;