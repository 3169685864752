import React,{Fragment, useEffect, useContext, useState} from 'react';
import { useHistory,useLocation, Link } from "react-router-dom";
import { GlobalContext } from '../context';
import { Apis } from '../utils/Apis';
import Footer from './Footer';
import Header from './Header';
import TransactionAmount from './TransactionAmount';

const SingleBeneficiary = ({match}) => {

    const [beneficiary, setBeneficiary] = useState(null)
    const [transactions, setTransactions] = useState([]);
    
    const {authenticated,loading, setLoading, username, token} = useContext(GlobalContext)
    const history = useHistory();
    const location = useLocation();

    let beneficiary_id = match.params.slug;

    useEffect(() => {

        if ( authenticated === "false" ) {
            history.push('/login');
        }

        if (match === undefined) {
            history.push('/beneficiaries');
        }
        
        getBeneficiary();
        getTransactions();

    }, [])


    const getBeneficiary =  async() => {
      setLoading(true);
      let data = JSON.stringify({ username : username, token : token});
      await Apis.post(`beneficiaries/${beneficiary_id}`, data)
      .then(data => {
        setLoading(false)
        if (data.status == "SUCCESS"){
            setBeneficiary(data.data)
            console.log(data.data)
        }
        else {
            if ( typeof data.errors !== 'undefined' && typeof data.errors !== null) {
            console.log(JSON.stringify(data.errors));
          }else{
            console.log(data.message);
          }
        }
      })
      .catch(e => setLoading(false) )
      .finally(() =>  setLoading(false) );
    }


    const getTransactions =  async() => {
      setLoading(true);
      let data = JSON.stringify({ username : username, token : token, beneficiary_id: beneficiary_id });
      await Apis.post(`transactions`, data)
      .then(data => {
        setLoading(false)
        if (data.status == "SUCCESS") {
            setTransactions(data.data)
            console.log(data.data)
        }
        else console.log(data.message);
      })
      .catch(e => setLoading(false) )
      .finally(() =>  setLoading(false) );
    }


  return (
    <Fragment >

      <Header hasLogout hasBack title='Beneficiary' link='/beneficiaries' />

        { !loading && beneficiary ?  
        <>
        
        <div className="container">
            { location.flash ? (
                <div className="alert alert-success mt-4" role="alert">
                <button type="button" className="close" data-dismiss="alert" aria-label="Close">
                    <span aria-hidden="true">×</span>
                </button>
                { location.flash }
                </div>
            ) : null }
            <div className="card bg-template-custom shadow mt-4 h-190">
                <div className="card-body">
                    <div className="row">
                        <div className="col-auto">
                            <figure className="avatar avatar-60"><img src="/assets/img/avatar.png" alt="" /></figure>
                        </div>
                        <div className="col pl-0 align-self-center">
                            <h5 className="mb-1">{beneficiary.name}</h5>
                            <p><img className="flag-img" style={{marginTop:-3}} src={`https://remit.accessforex.com/admin/images/flags/${(beneficiary.country_iso_code).toLowerCase()}.png`} alt="" /> Zimbabwe <small className="float-right text-mute">{beneficiary.mobile}</small></p>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div className="container top-100">
            <div className="card mb-4 shadow">
                {
                    transactions.length === 0 ? ( 
                        <div className="card-body border-bottom">
                            <div className="row">
                                <div className="col">
                                    <p align="center">You haven't remitted to this beneficiary yet</p>
                                </div>
                            </div>
                        </div>
                    ) :
                    transactions.map((trans, index) => {
                        return(
                        <div key={index} className="card-body border-bottom">
                            <Link to={"/transactions/"+trans.trans_ref}>
                            <div className="row">
                                <div className="col">
                                    <p>{trans.trans_ref}<br/><small className="text-mute text-secondary">{trans.creation_date}</small></p>
                                </div>
                                <div className="col text-right">
                                    <p> <TransactionAmount id={trans.trans_ref}/> <br/><small className="text-mute text-secondary">{trans.status}</small></p>
                                </div>
                            </div>
                            </Link>
                        </div>
                        )
                    })
                
                }
               
               
                <div className="card-footer bg-none">
                <Link to="/send-money" className="btn btn-default shadow  btn-rounded btn-block btn-lg ">Send Money</Link>
                </div>
                
            </div>
        </div>

        <br/>
        <div className="container text-center">
            <Link to={`/beneficiaries/update/${beneficiary.beneficiary_id}`} className="shadow mr-2 btn btn-default rounded mb-2">Edit Beneficiary</Link>
        </div>

        </>

        : null }
        
        <Footer/>

    </Fragment>
  );
};

export default SingleBeneficiary;