import React,{Fragment, useEffect, useContext, useState} from 'react';
import { useHistory,useLocation, Link } from "react-router-dom";
import { GlobalContext } from '../context';
import { Apis } from '../utils/Apis';
import Footer from './Footer';
import Header from './Header';
import Status from './ui/Status';

const Beneficiaries = (props) => {

    const {authenticated,status, username, token,loading,setLoading} = useContext(GlobalContext)
    const history = useHistory();
    const location = useLocation();

    const [beneficiaries, setBeneficiaries] = useState([])


    useEffect(() => {
        if(!authenticated) {
            history.push('/login');
        }

        if( JSON.parse(status).status !== 'blocked' ) getBeneficiaries();
        
    }, [])

    
    const getBeneficiaries =  async() => {
      setLoading(true);
      let data = JSON.stringify({ username : username, token : token });
      console.log(data)
      await Apis.post(`beneficiaries`, data)
      .then(data => {
        setLoading(false)
        console.log(data)
        if (data.status == "SUCCESS") setBeneficiaries(data.data)
        else console.log(data.message);
      })
      .catch(e => setLoading(false) )
      .finally(() =>  setLoading(false) );
    }



  return (
    <Fragment >


      <Header hasBack hasLogout title='Beneficiaries' link='/' />

    <div className="container">
            <Status/>
            {/* <h6 className="subtitle text-center">Your Beneficiaries</h6> */}
            { location.flash ? (
              <div className="alert alert-success" role="alert">
                <button type="button" className="close" data-dismiss="alert" aria-label="Close">
                    <span aria-hidden="true">×</span>
                </button>
                { location.flash }
              </div>
            ) : null }
            <div className="row">
                <div className="col-12 px-0">
                    <ul className="list-group list-group-flush border-top border-bottom">

                        {beneficiaries.map((benef, index) => {
                            return(
                                <li key={index} className="list-group-item">
                                    <Link to={"/beneficiaries/"+benef.beneficiary_id} style={{textDecoration: 'none'}}>
                                    <div className="row align-items-center">
                                        <div className="col-auto pr-0">
                                            <div className="avatar avatar-50 no-shadow border-0">
                                                <img src="/assets/img/avatar.png" alt=""/>
                                            </div>
                                        </div>
                                        <div className="col align-self-center pr-0">
                                            <h6 className="font-weight-normal mb-1">{benef.name}</h6>
                                            <p className="text-mute small text-secondary">{benef.mobile}</p>
                                        </div>
                                        <div className="col-auto">
                                            <img className="flag-img" src={`https://remit.accessforex.com/admin/images/flags/${(benef.country_iso_code).toLowerCase()}.png`} alt=""/>
                                        </div>
                                    </div>
                                    </Link>
                                </li>
                            )
                        })}
                       
                    </ul>
                </div>
            </div>
        </div>
        <br/>
        <div className="container text-center">
            <Link to="/beneficiaries/new" className="shadow mr-2 btn btn-default rounded mb-2">Add New Beneficiary</Link>
        </div>

        
        <Footer/>

    </Fragment>
  );
};

export default Beneficiaries;