import React,{useContext} from 'react';
import { GlobalContext } from '../context';

const Modals = () => {

  const { signOut } = useContext(GlobalContext);

  return (
    <>
      <div className="modal fade" id="logout" tabIndex="-1" role="dialog" aria-hidden="true">
        <div className="modal-dialog modal-sm modal-dialog-centered" role="document">
            <div className="modal-content">
                <div className="modal-header border-0">
                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div className="modal-body text-center pt-0">
                    <h5 className="text-mute">Are you sure you want to sign out?</h5>
                </div>
                <div className="modal-footer border-0">
                    {/* <button type="button" className="btn btn-default btn-lg btn-rounded shadow btn-block close" data-dismiss="modal">Logout</button> */}
                    <button onClick={() => signOut()} type="button" className="btn btn-default btn-lg btn-rounded shadow btn-block" data-dismiss="modal">Logout</button>
                </div>
            </div>
        </div>
      </div>
      
    </>
  );
};

export default Modals;