const AppConst = {
  'username': '@accessforex.com:username',
  'token': '@accessforex.com:token',
  'country': '@accessforex.com:country',
  'status': '@accessforex.com:status',
  'authenticated': '@accessforex.com:authenticated',
}

const isEmpty = (val) => {
  return (val == '' || val == null) ? true : false;
}

const isObjectEmpty = (obj) => {

    for (var prop in obj) {
        return false;
    }
    return true;
    
}

const capitalize = (s) => {
  if (typeof s !== 'string') return ''
  return s.charAt(0).toUpperCase() + s.slice(1)
}

// Set the top cordinate to 0
// make scrolling smooth
const scrollToTop = () => {
  window.scrollTo({
    top: 0,
    behavior: "smooth"
  });
};



export { AppConst, isEmpty, isObjectEmpty, scrollToTop, capitalize }