import React, {useState, useContext, Fragment, useEffect, useRef} from 'react';
import { useHistory, Link } from "react-router-dom";
import Header from './Header';
import { YearPicker, MonthPicker, DayPicker } from 'react-dropdown-date';
import ReactFlagsSelect from 'react-flags-select';
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import { GlobalContext } from '../context';
import { Apis } from '../utils/Apis';
import { isEmpty } from '../utils/AppConst';
import FormValidation from '../components/FormValidation';
import Loading from './Loading';

import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import loadjs from "loadjs";

const Register = () => {

  const { authenticated, setUsername, setToken, loading, setLoading} = useContext(GlobalContext);

  const history = useHistory();

  useEffect(() => {
    if(authenticated === "true") {
        history.push('/');
    }

    loadjs([
        '/assets/js/jquery-3.3.1.min.js',
        '/assets/js/popper.min.js', 
        '/assets/vendor/bootstrap-4.4.1/js/bootstrap.min.js', 
        '/assets/vendor/swiper/js/swiper.min.js', 
        '/assets/js/main.js', 
    ]);

    // get list of source countries
    preLoad();

    
  },[])




  const preLoad = async () => {

    setLoading(true);

    await Apis.get(`ui/countries`)
    .then(data => {
      // setLoading(false)
      if (data.status == "SUCCESS") {
        let source_countries = data.data.source_countries
        let allowed = [];
        source_countries.forEach(function (c, i) {
          allowed = allowed.concat(`${(c.iso_code).toUpperCase()}`);
        });
        setCountryOptions(allowed);
      } else {
        setErrors([{message: data.message}]);
      }
    })
    .catch(e => {
      setErrors([{message: e.message}]);
      setLoading(false);
    })
    .finally(() => {
      setLoading(false);
    });

  }

  const [countryOptions, setCountryOptions] = useState([]);

  // errors & validations
  const [errors, setErrors] = useState([]);
  const [apiErrors, setApiErrors] = useState([])

  const initialValues = {
    country: '',
    firstName: '',
    lastName: '',
    email: '',
    day: '',
    month: '',
    year: '',
    phone: '',
    password: '',
    confirmPassword: '',
    referralCode: '',
  }

  const errorMessages = {
    firstName: 'Invalid first name. Please enter your name as it appears on your formal identification',
    lastName: 'Invalid last name. Please enter your name as it appears on your formal identification',
    dob: 'Please select a valid date of birth',
    email: 'Invalid email address',
    password: 'Password must be at least 8 characters long. It must contain at least: 1 upper case letter. 1 lower case letter. 1 number.'
  }

  const signInSchema = Yup.object().shape({
    country: Yup.string().required().label('Country'),
    firstName: Yup.string().min(2,errorMessages.firstName).required().label('First Name'),
    lastName: Yup.string().min(2, errorMessages.lastName).required().label('Last Name'),
    email: Yup.string().email(errorMessages.email).required("Email is required").label('Email Address'),
    // day: Yup.number().required().label('Day'),
    // month: Yup.number().required().label('Month'),
    // year: Yup.number().required().label('Year'),
    // phone: Yup.number().required().label('Phone Number'),
    password: Yup.string().required("Password is required").min(8, "Password is too short").label('Password'),
    // confirmPassword: Yup.string().oneOf([Yup.ref('password'), null], 'Passwords do not match').label('Confirm Password')
    confirmPassword: Yup.string().when("password", {
    is: val => (val && val.length > 0 ? true : false),
      then: Yup.string().oneOf(
        [Yup.ref("password")],
        "Both passwords need to be the same"
      )
    })
  });

  const ref = useRef(null);

  const submitForm = async () => {

    let formValues = ref.current.values;

    // console.log(formValues)
    // console.log(formValues.day+'-'+formValues.month+'-'+formValues.year)

    setLoading(true);

    let day = ( (formValues.day).toString().length == 1) ? '0' + formValues.day : formValues.day;
    let month = !isEmpty(formValues.month) ? eval(formValues.month) + 1 : '';
    if ( (month).toString().length == 1) month = '0' + month;

    let data = JSON.stringify({
      country : formValues.country,
      first_name : formValues.firstName,
      last_name : formValues.lastName,
      email : formValues.email,
      mobile : `+${formValues.phone}`,
      dob : [formValues.year, month, day].join('-'),
      subscribe : 'true',
      password : formValues.password
    });

    await Apis.post(`auth/register`, data)
    .then(data => {
      // setLoading(false)
      console.log(data);
      if (data.status == "SUCCESS") {
        
        // setAuthentication(true)
        // signIn()
        // setToken(data.session_token)
        setUsername(formValues.email);

        // history.push('/verify-registration');
        history.push({
           pathname: '/verify-registration',
           phone: `+${formValues.phone}`,
        });
        
      } else {

        if ( typeof data.errors !== 'undefined' && typeof data.errors !== null) {
          setApiErrors([data.errors]);
        }else{
          setApiErrors([data.message]);
        }

      }

    })
    .catch(e => {
      setApiErrors([{message: e.message}]);
      setLoading(false)
    })
    .finally(() => {
      setLoading(false);
    });


  }

  return (
    <Fragment>

    <Loading active={loading} />

    <Header hasBack link='/login' title='Register' icon='chevron_left' />

    

    <div className="row no-gutters login-row">
            <div className="col px-3 text-center">
                {/* <br/>
                <img src="/assets/img/af-logo.png" alt="logo" className="logo-small" />
                <br/> */}
                {apiErrors.length > 0 && apiErrors.map((error, index) => (
                  <div key={index} className="alert alert-danger mt-4" role="alert">
                    <button type="button" className="close" data-dismiss="alert" aria-label="Close">
                        <span aria-hidden="true">×</span>
                    </button>
                    {error.error}
                  </div>
                ))}

                <Formik
                  innerRef={ref}
                  initialValues={initialValues}
                  validationSchema={signInSchema}
                  // onSubmit={submitForm}
                >
                  {(formik) => {
                    const {
                      values,
                      handleChange,
                      handleSubmit,
                      errors,
                      touched,
                      handleBlur,
                      isValid,
                      dirty,
                      setFieldValue
                    } = formik;
                    return (
                      
                
                <form onSubmit={handleSubmit} className="form-signin mt-3 " autoComplete="off">
                    <div className="form-group float-label">
                        <label>Country *</label>
                        <ReactFlagsSelect
                            name="country"
                            selected={values.country}
                            onSelect={code => setFieldValue('country', code,true)}
                            // onSelect={handleChange}
                            onBlur={handleBlur}
                            countries={countryOptions}
                            // countries={["GB", "ZA"]}
                            placeholder="Select Country"
                            selectedSize={10}
                            optionsSize={14}
                            // className="select-flags"
                            className={ `select-flags ${ errors.country && touched.country ? "is-invalid" : ( touched.country ? "is-valid" : null ) }`  } 
                        />
                        {errors.country && touched.country && ( <span className="form-text text-muted small text-danger pl-3">{errors.country}</span> )}
                    </div>

                    <div className="form-group">
                        <label>First Name *</label>
                        <input type="text" 
                          name="firstName"
                          value={values.firstName} 
                          onChange={handleChange} 
                          onBlur={handleBlur}
                          className={ `form-control form-control ${ errors.firstName && touched.firstName ? "is-invalid" : ( touched.firstName ? "is-valid" : null ) }`  } 
                        />
                        {/* {errors.firstName && touched.firstName && ( <div className="invalid-tooltip"> {errors.firstName} </div> )} */}
                        {errors.firstName && touched.firstName && ( <span className="form-text text-muted small text-danger">{errors.firstName}</span> )}
                    </div>

                    {/* <br/> */}
                    <div className="form-group">
                    <label>Last Name *</label>
                        <input type="text" 
                          name="lastName"
                          value={values.lastName} 
                          onChange={handleChange} 
                          onBlur={handleBlur}
                          className={ `form-control form-control ${ errors.lastName && touched.lastName ? "is-invalid" : ( touched.lastName ? "is-valid" : null ) }`  } 
                        />
                        
                        {errors.lastName && touched.lastName && ( <span className="form-text text-muted small text-danger">{errors.lastName}</span> )}
                    </div>

                    <div className="form-group">
                    <label>Date of Birth *</label>

                      <div className="row">
                          <div className="col-4">
                          <DayPicker
                              // name="dateYear"
                              defaultValue={'Day'}
                              year={values.year}    // mandatory
                              month={values.month}  // mandatory
                              endYearGiven              // mandatory if end={} is given in YearPicker
                              required={false}           // default is false
                              disabled={false}           // default is false
                              value={values.day}    // mandatory
                              onChange={(day) => setFieldValue('day', day)}
                              // onChange = {handleChange}
                              onBlur={handleBlur}
                              // onChange={(e) => setFieldValue('day', e,true)}
                              id={'day'}
                              name={'day'}
                              classes={'date-select'}
                              optionClasses={'option classes'}
                          />
                          </div>
                          <div className="col-4">
                          <MonthPicker
                          // name="dateMonth"
                              defaultValue={'Month'}
                              // numeric                   // to get months as numbers
                              // short                     // default is full name
                              // caps                      // default is Titlecase
                              endYearGiven              // mandatory if end={} is given in YearPicker
                              year={values.year}    // mandatory
                              required={false}           // default is false
                              disabled={false}           // default is false
                              value={values.month}  // mandatory
                              // onChange={(month) => {    // mandatory
                              // setDateMonth(month)
                              // }}
                              // onChange = {handleChange}
                              onChange={(month) => setFieldValue('month', month)}
                              onBlur={handleBlur}
                              id={'month'}
                              name={'month'}
                              classes={'date-select'}
                              optionClasses={'option classes'}
                          />
                          </div>
                          <div className="col-4">
                          <YearPicker
                              // name="dateYear"
                              defaultValue={'Year'}
                              // start={ (new Date().getFullYear()) - 16 }                // default is 1900
                              end={ (new Date().getFullYear()) - 18 }                  // default is current year
                              reverse                     // default is ASCENDING
                              required={false}             // default is false
                              disabled={false}             // default is false
                              value={values.year}     // mandatory
                              // onChange={(values.year) => {       // mandatory
                              // setDateYear(year);
                              // }}
                              // onChange={handleChange}
                              onChange={(year) => setFieldValue('year', year)}
                              onBlur={handleBlur}
                              id={'year'}
                              name={'year'}
                              classes={'date-select'}
                              optionClasses={'option classes'}
                          />
                          </div>
                      </div>

                      { ( (errors.day && touched.day) || (errors.month && touched.month) || (errors.year && touched.year) ) && ( <span className="form-text text-muted small text-danger">{errors.day}</span> )}

                    </div>

                    <div className="form-group">
                        <label>Email Address *</label>
                        <input type="text" 
                          name="email"
                          className={ `form-control form-control ${ errors.email && touched.email ? "is-invalid" : ( touched.email ? "is-valid" : null ) }`  } 
                          value={values.email} 
                          onChange={handleChange} 
                          onBlur={handleBlur}
                        />
                        {errors.email && touched.email && ( <span className="form-text text-muted small text-danger">{errors.email}</span> )}
                    </div>


                    <div className="form-group">
                        <label>Mobile Number *</label>
                        <PhoneInput
                            name="phone"
                            country={ !isEmpty(values.country) ? (values.country).toLowerCase() : 'gb'}
                            value={values.phone}
                            // onChange={handleChange}
                            onChange={(phone) => setFieldValue('phone', phone)}
                            onBlur={handleBlur}
                            // onChange={phone => setPhone(phone)}
                            placeholder=''
                            // containerClass='phone-input'
                            containerClass={ `phone-input ${ errors.phone && touched.phone ? "is-invalid" : ( touched.phone ? "is-valid" : null ) }`  } 
                            autoFormat={false}
                        />
                    </div>

                    <div className="form-group">
                        <label>Password *</label>
                        <input type="password" 
                          name="password"
                          // className="form-control form-control" 
                          className={ `form-control form-control ${ errors.password && touched.password ? "is-invalid" : ( touched.password ? "is-valid" : null ) }`  } 
                          value={values.password} 
                          onChange={handleChange} 
                          onBlur={handleBlur}
                        />
                        {errors.password && touched.password && ( <span className="form-text text-muted small text-danger">{errors.password}</span> )}
                    </div>

                    <div className="form-group">
                        <label>Confirm Password *</label>
                        <input type="password" 
                          name="confirmPassword"
                          // className="form-control form-control" 
                          className={ `form-control form-control ${ errors.confirmPassword && touched.confirmPassword ? "is-invalid" : ( touched.confirmPassword ? "is-valid" : null ) }`  } 
                          value={values.confirmPassword} 
                          onChange={handleChange} 
                          onBlur={handleBlur}
                        />
                        {errors.confirmPassword && touched.confirmPassword && ( <span className="form-text text-muted small text-danger">{errors.confirmPassword}</span> )}
                    </div>


                    <div className="form-group">
                        <label>Referral Code (Optional)</label>
                        <input type="password" 
                        name="referralCode"
                        className='form-control'
                        // className={ `form-control form-control ${ errors.referralCode && touched.referralCode ? "is-invalid" : ( touched.referralCode ? "is-valid" : null ) }`  } 
                        value={values.referralCode} 
                        onChange={handleChange} 
                        />
                        {errors.referralCode && touched.referralCode && ( <span className="form-text text-muted small text-danger">{errors.referralCode}</span> )}
                    </div> 

                      <div className="row mx-0">
                          <div className="col">
                              <p align="center" className="terms">By creating an account you accept our Terms of Service</p>
                              <button 
                              // className="btn btn-default btn-lg btn-rounded shadow btn-block" 
                              className={ `btn btn-default btn-lg btn-rounded shadow btn-block ${ dirty && isValid ? " btn-default " : "btn-secondary" }` }
                              disabled={!(dirty && isValid)}
                              onClick={() => submitForm()}
                              >Create Account</button>
                          </div>
                      </div>
                      <div className="row mx-0">
                          <div className="col">
                              <Link to='/login' className="mt-4 d-block text-center">Sign In</Link>
                          </div>
                          <div className="col">
                              <Link to='/forgot-password' className="mt-4 d-block text-center">Forgot Password?</Link>
                          </div>
                      </div>
                      {/* <p>{JSON.stringify(errors)}</p> */}
                  
                </form>    
                
                );
                }}

              </Formik>

            </div>
        </div>

        <br/>

        

        
     
    </Fragment>
    
  );
};

export default Register;