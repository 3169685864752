import React,{Fragment, useEffect, useContext, useState, useRef} from 'react';
import { useHistory, Link } from "react-router-dom";
import { GlobalContext } from '../context';
// import { ApiCall } from '../utils/APICall';
import ApiErrors from './ApiErrors';
import Footer from './Footer';
import Header from './Header';
import Alert from './ui/Alert';

import { Formik, Form, Field, ErrorMessage } from "formik";
import { changePasswordSchema } from '../utils/Yup';
import { Apis } from '../utils/Apis';


const ChangePassword = () => {

    const {authenticated, username, token, loading, setLoading} = useContext(GlobalContext)
    const [passwordChanged, setPasswordChanged] = useState(false)

    const history = useHistory();

    useEffect(() => {
        if(!authenticated) {
            history.push('/login');
        }
    }, [])

    const [errors, setErrors] = useState([])
    const [apiErrors, setApiErrors] = useState([])

    const initialValues = {
        currentPassword: '',
        newPassword: '',
        confirmPassword: ''
    }

    const ref = useRef(null);

    const submitForm = async (values, {setSubmitting, setErrors, setStatus, resetForm}) => {

        // let formValues = ref.current.values;

        let formValues = values;

        setApiErrors([])
        setLoading(true);


        let data = JSON.stringify({
            username : username,
            current_password : formValues.currentPassword,
            new_password : formValues.newPassword,
            token : token
        });

        await Apis.post(`auth/change-password`, data)
        .then(data => {
            if (data.status == "SUCCESS") {
                setPasswordChanged(true)
                resetForm({})
            } else {
                if ( typeof data.errors !== 'undefined' && typeof data.errors !== null) setApiErrors(data.errors);
                else setApiErrors([data.message]);
            }

        })
        .catch(e => setApiErrors([{message: e.message}]) )
        .finally(() => setLoading(false) );

    }

  return (
    <Fragment >

      <Header hasBack title='Change Password' hasLogout link='/profile' />

        <div className="container">
            <div className="row">
                <div className="col-12 col-sm-8 col-md-6 col-lg-5 col-xl-4 mx-auto text-center">
                    {/* <h4 className="mt-5"><span className="font-weight-light">Change </span>Password</h4> */}
                    {/* <br/> */}
                    <ApiErrors errors={apiErrors} />
                    {passwordChanged &&<Alert type='success' text='Password Successfully Changed'/>}
                    <br/>
                    <Formik
                  innerRef={ref}
                  initialValues={initialValues}
                  validationSchema={changePasswordSchema}
                  onSubmit={submitForm}
                >
                  {(formik) => {
                    const {
                      values,
                      handleChange,
                      handleSubmit,
                      errors,
                      touched,
                      handleBlur,
                      isValid,
                      dirty,
                      setFieldValue
                    } = formik;
                    return (
                    <form onSubmit={handleSubmit} className="form-signin mt-3 " autoComplete="off">

                        <div className="form-group">
                            <label>Current Password</label>
                            <input type="password" 
                            name="currentPassword"
                            className={ `form-control form-control-lg ${ errors.currentPassword && touched.currentPassword ? "is-invalid" : ( touched.currentPassword ? "is-valid" : null ) }`  } 
                            value={values.currentPassword} 
                            onChange={handleChange} 
                            onBlur={handleBlur}
                            autoComplete="off"
                            />
                            {errors.currentPassword && touched.currentPassword && ( <span className="form-text text-muted small text-danger">{errors.currentPassword}</span> )}
                        </div>
                        <div className="form-group">
                            <label>New Password</label>
                            <input type="password" 
                            name="newPassword"
                            className={ `form-control form-control-lg ${ errors.newPassword && touched.newPassword ? "is-invalid" : ( touched.newPassword ? "is-valid" : null ) }`  } 
                            value={values.newPassword} 
                            onChange={handleChange} 
                            onBlur={handleBlur}
                            autoComplete="off"
                            />
                            {errors.newPassword && touched.newPassword && ( <span className="form-text text-muted small text-danger">{errors.newPassword}</span> )}
                        </div>
                        <div className="form-group">
                            <label>Confirm Password</label>
                            <input type="password" 
                            name="confirmPassword"
                            className={ `form-control form-control-lg ${ errors.confirmPassword && touched.confirmPassword ? "is-invalid" : ( touched.confirmPassword ? "is-valid" : null ) }`  } 
                            value={values.confirmPassword} 
                            onChange={handleChange} 
                            onBlur={handleBlur}
                            autoComplete="off"
                            />
                            {errors.confirmPassword && touched.confirmPassword && ( <span className="form-text text-muted small text-danger">{errors.confirmPassword}</span> )}
                        </div>
                        
                        <div className="row mt-4">
                            <div className="col">
                                <button 
                                type='submit'
                                className={ `btn btn-lg btn-block btn-rounded shadow ${ dirty && isValid ? " btn-default " : "btn-secondary" }` }
                                disabled={!(dirty && isValid)}
                                // onClick={() => submitForm()}
                                >Update Password</button>
                            </div>
                        </div>

                    </form>
                    );
                    }}
                    </Formik>
                </div>
            </div>
        </div>
        
        <Footer/>

    </Fragment>
  );
};

export default ChangePassword;