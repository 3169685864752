import React,{useContext} from 'react';
import { GlobalContext } from '../../context';

const Status = () => {

  const {status} = useContext(GlobalContext)

  return (
    <>

    { status && JSON.parse(status).status == 'blocked' && 
      <div class="alert alert-danger mt-4" role="alert">
          {JSON.parse(status).type !== 'registered' && <h4 class="alert-heading text-center">Update Your Profile</h4> }
          <p className="text-center">{JSON.parse(status).type == 'registered' ? 'Your account is awaiting verification. This process usually takes ten minutes to an hour, during working hours.' : 'For you to be able to send money, please update your profile.'}</p>
      </div>
    }
      
    </>
  );
};

export default Status;