import React,{Fragment,useState, useEffect, useContext} from 'react';
import { useHistory, Link } from "react-router-dom";
import { GlobalContext } from '../context';
import { Apis } from '../utils/Apis';
import { AppConst, capitalize } from '../utils/AppConst';
import Footer from './Footer';
import Header from './Header';
import Loading from './Loading';
import TransactionAmount from './TransactionAmount';
import Status from './ui/Status';

const Transactions = () => {

    const {loading, setLoading, authenticated,username,token,status} = useContext(GlobalContext)
    const [transactions, setTransactions] = useState([]);

    const history = useHistory();
    
    useEffect(() => {

        if(!authenticated && !localStorage.getItem(AppConst.authenticated)) {
            history.push('/login');
        }


        if( JSON.parse(status).status !== 'blocked' )  getTransactions();
    }, [])

    const getTransactions =  async() => {
      setLoading(true);
      let data = JSON.stringify({ username : username, token : token });
      await Apis.post(`transactions`, data)
      .then(data => {
        setLoading(false)
        if (data.status == "SUCCESS") setTransactions(data.data)
        else console.log(data.message);
      })
      .catch(e => setLoading(false) )
      .finally(() =>  setLoading(false) );
    }




  return (
    <Fragment >
    <Loading active={loading} />

    <Header hasBack hasLogout title='Transactions' />

    {/* <div className="container">
        <h4 className="mt-5" align="center"><span className="font-weight-light">All </span>Transactions</h4>
    </div> */}

    <div className="container">
            <Status />
            {/* <input type="text" className="form-control form-control-lg search my-3" placeholder="Search"/> */}
            
            <div className="row">
                <div className="col-12 px-0">
                    <ul className="list-group list-group-flush border-top border-bottom">

                        {transactions.map((trans, index) => {
                        {/* {transactions.slice(0, 3).map((trans, index) => { */}
                            return(
                                <li key={index} className="list-group-item">
                                    <Link 
                                    to={{
                                        pathname: `/transactions/${trans.trans_ref}`,
                                        data: trans
                                    }} 
                                    style={{textDecoration: 'none'}}>
                                    <div className="row align-items-center">
                                        <div className="col align-self-center pr-0">
                                            <h6 className="font-weight-normal mb-1">{trans.benef_name}</h6>
                                            <p className="text-mute small text-secondary">{trans.creation_date}, {trans.trans_ref}</p>
                                        </div>
                                        <div className="col-auto">
                                            <h6 className="text-right"> <TransactionAmount id={trans.trans_ref} /> </h6>
                                            {/* <h6 className="text-right">{trans.send_currency === "ZAR" ? "R" : "£"} {trans.send_amount}</h6> */}
                                            <p className="text-mute small text-secondary text-right text-success">{trans.status === "PENDING_CLEARANCE" ? "Awaiting Payment" : ( trans.status === "SENT_FOR_PAY" ? "Awaiting Collection" : ( trans.status === "PROCESSED" ? "Collected"  : capitalize(trans.status)  ) ) }</p>
                                        </div>
                                    </div>
                                    </Link>
                                </li>
                            )
                        })}

                     
                    </ul>
                </div>
            </div>
        </div>


    <Footer page="Transactions"/>

    </Fragment>
  );
};

export default Transactions;