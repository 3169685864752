import React, {useState, useContext, Fragment, useRef} from 'react';
import { useHistory, Link } from "react-router-dom";
import Header from './Header';
import { YearPicker, MonthPicker, DayPicker } from 'react-dropdown-date';
import { GlobalContext } from '../context';

import { Apis } from '../utils/Apis';

import { Formik, Form, Field, ErrorMessage } from "formik";

import { isEmpty } from '../utils/AppConst';
import { forgotPasswordSchema } from '../utils/Yup';
import ApiErrors from './ApiErrors';

const ForgotPassword = () => {

    const { username,setUsername, token, loading, setLoading} = useContext(GlobalContext);

    const history = useHistory();

    const [errors, setErrors] = useState([])
    const [apiErrors, setApiErrors] = useState([])

    const initialValues = {
        email: '',
        day: '',
        month: '',
        year: '',
    }


    const ref = useRef(null);

    const submitForm = async () => {

        let formValues = ref.current.values;
        
        setLoading(true);

        let day = ( (formValues.day).toString().length == 1) ? '0' + formValues.day : formValues.day;
        let month = !isEmpty(formValues.month) ? eval(formValues.month) + 1 : '';
        if ( (month).toString().length == 1) month = '0' + month;

        let data = JSON.stringify({
            username : formValues.email,
            dob : [formValues.year, month, day].join('-')
        });

        await Apis.post(`auth/forgot-password`, data)
        .then(data => {
        // setLoading(false)
        console.log(data);
        if (data.status == "SUCCESS") {
            
           
            setUsername(formValues.email);

            history.push('/reset-password');
            // history.push({
            // pathname: '/verify-registration',
            // phone: `+${formValues.phone}`,
            // });
            
        } else {

            if ( typeof data.errors !== 'undefined' && typeof data.errors !== null) {
            setApiErrors(data.errors);
            }else{
            setApiErrors([data.message]);
            }

        }

        })
        .catch(e => {
            setApiErrors([{message: e.message}]);
            setLoading(false)
        })
        .finally(() => {
            setLoading(false);
        });


    }

  

  return (
    <Fragment>

    <Header title='Reset Password' link='/login' hasBack icon='chevron_left' />

    <div className="row no-gutters login-row">
            <div className="col px-3 text-center">
                <br/>
                <img src="/assets/img/af-logo.png" alt="logo" className="logo-small" />

                <ApiErrors errors={apiErrors} />

                <Formik
                  innerRef={ref}
                  initialValues={initialValues}
                  validationSchema={forgotPasswordSchema}
                  // onSubmit={submitForm}
                >
                  {(formik) => {
                    const {
                      values,
                      handleChange,
                      handleSubmit,
                      errors,
                      touched,
                      handleBlur,
                      isValid,
                      dirty,
                      setFieldValue
                    } = formik;
                    return (
                <form onSubmit={handleSubmit} className="form-signin mt-3 " autoComplete="off">
                    <div className="form-group">
                        <label>Email Address *</label>
                        <input type="text" 
                          name="email"
                          className={ `form-control form-control ${ errors.email && touched.email ? "is-invalid" : ( touched.email ? "is-valid" : null ) }`  } 
                          value={values.email} 
                          onChange={handleChange} 
                          onBlur={handleBlur}
                          autoComplete="off"
                        />
                        {errors.email && touched.email && ( <span className="form-text text-muted small text-danger">{errors.email}</span> )}
                    </div>
                  
                    <div className="form-group">
                    <label>Date of Birth *</label>

                      <div className="row">
                          <div className="col-4">
                          <DayPicker
                              // name="dateYear"
                              defaultValue={'Day'}
                              year={values.year}    // mandatory
                              month={values.month}  // mandatory
                              endYearGiven              // mandatory if end={} is given in YearPicker
                              required={false}           // default is false
                              disabled={false}           // default is false
                              value={values.day}    // mandatory
                              onChange={(day) => setFieldValue('day', day)}
                              // onChange = {handleChange}
                              onBlur={handleBlur}
                              // onChange={(e) => setFieldValue('day', e,true)}
                              id={'day'}
                              name={'day'}
                              classes={'date-select'}
                              optionClasses={'option classes'}
                          />
                          </div>
                          <div className="col-4">
                          <MonthPicker
                          // name="dateMonth"
                              defaultValue={'Month'}
                              // numeric                   // to get months as numbers
                              // short                     // default is full name
                              // caps                      // default is Titlecase
                              endYearGiven              // mandatory if end={} is given in YearPicker
                              year={values.year}    // mandatory
                              required={false}           // default is false
                              disabled={false}           // default is false
                              value={values.month}  // mandatory
                              // onChange={(month) => {    // mandatory
                              // setDateMonth(month)
                              // }}
                              // onChange = {handleChange}
                              onChange={(month) => setFieldValue('month', month)}
                              onBlur={handleBlur}
                              id={'month'}
                              name={'month'}
                              classes={'date-select'}
                              optionClasses={'option classes'}
                          />
                          </div>
                          <div className="col-4">
                          <YearPicker
                              // name="dateYear"
                              defaultValue={'Year'}
                              // start={ (new Date().getFullYear()) - 16 }                // default is 1900
                              end={ (new Date().getFullYear()) - 18 }                  // default is current year
                              reverse                     // default is ASCENDING
                              required={false}             // default is false
                              disabled={false}             // default is false
                              value={values.year}     // mandatory
                              // onChange={(values.year) => {       // mandatory
                              // setDateYear(year);
                              // }}
                              // onChange={handleChange}
                              onChange={(year) => setFieldValue('year', year)}
                              onBlur={handleBlur}
                              id={'year'}
                              name={'year'}
                              classes={'date-select'}
                              optionClasses={'option classes'}
                          />
                          </div>
                      </div>

                      {  errors.month ||  errors.day || errors.year  ?  <span className="form-text text-muted small text-danger">Please select a valid date of birth.</span> : null}
                      {/* { ( touched.year || touched.month || touched.day) && (errors.month ||  errors.day || errors.year ) ? ( <span className="form-text text-muted small text-danger">Please select a valid date of birth.</span> ) : null} */}

                    </div>
                    
                    <p className="text-secondary mt-4 d-block">Enter your email address and select your date of birth</p>

                    <div className="row mx-0">
                        <div className="col">
                            <button 
                              className={ `btn btn-default btn-lg btn-rounded shadow btn-block ${ dirty && isValid ? " btn-default " : "btn-secondary" }` }
                              disabled={!(dirty && isValid)}
                              onClick={() => submitForm()}
                              >Reset Password</button>
                        </div>
                    </div>
                    <div className="row mx-0">
                        <div className="col">
                            <Link to='/register' className="mt-4 d-block text-center">Register Now</Link>
                        </div>
                        <div className="col">
                            <Link to='/login' className="mt-4 d-block text-center">Sign In</Link>
                        </div>
                    </div>
                
                </form>
                );
                }}

              </Formik>               
            </div>
        </div>

        
     
    </Fragment>
    
  );
};

export default ForgotPassword;