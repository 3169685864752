import React, {useEffect} from 'react';
import { useHistory, Link } from "react-router-dom";
import loadjs from "loadjs";

const Footer = (props) => {
    const { page } = props;

    useEffect(() => {
        
        loadjs([
            '/assets/js/jquery-3.3.1.min.js',
            '/assets/js/popper.min.js', 
            '/assets/vendor/bootstrap-4.4.1/js/bootstrap.min.js', 
            '/assets/vendor/swiper/js/swiper.min.js', 
            '/assets/js/main.js', 
        ]);


    }, [])

  return (
    <>

    <div className="footer">
        <div className="no-gutters">
            <div className="col-auto mx-auto">
                <div className="row no-gutters justify-content-center">
                    <div className="col-auto">
                        <Link to="/" className={`btn btn-link-default ${page && page === 'Dashboard' ? 'active' : null}`}>
                            <i className="material-icons">home</i>
                        </Link>
                    </div>
                    <div className="col-auto">
                        <Link to="/send-money" className={`btn btn-link-default ${page && page === 'SendMoney' ? 'active' : null}`}>
                            <i className="material-icons">send</i>
                        </Link>
                    </div>
                    <div className="col-auto">
                        <Link to="/beneficiaries" className={`btn btn-link-default ${page && page === 'Beneficiaries' ? 'active' : null}`}>
                            <i className="material-icons">people</i>
                        </Link>
                    </div>
                    <div className="col-auto">
                        <Link to="/transactions" className={`btn btn-link-default ${page && page === 'Transactions' ? 'active' : null}`}>
                            <i className="material-icons">event</i>
                        </Link>
                    </div>
                    <div className="col-auto">
                        <Link to="/profile" className={`btn btn-link-default ${page && page === 'Profile' ? 'active' : null}`}>
                            <i className="material-icons">account_circle</i>
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    </div>



    
    </>
  );
};

export default Footer;