import React from 'react';

export const ListComp = ({title,subtext,bold}) => {
  return (
    <>
    <div className="card-body border-bottom">
        <div className="row">
            <div className="col">
                <p className="font-weight-bold small">{title}</p>
            </div>
            <div className={`col text-right small ${bold ? 'text-bold' : null}`}>
                <p>{subtext}</p>
            </div>
        </div>
    </div>
    </>
  )
}

const Verify = () => {


  return (
    <>

        <h6 align="center" className="subtitle">Amounts</h6>
        <div className="container">
            <div className="card mb-4 shadow">
              <ListComp title='Total to Pay' subtext='$10.46' bold />
              <ListComp title='Receiving Amount' subtext='$10.00' bold />
              <ListComp title='Rate' subtext='£1 = $1.3220' />
              <ListComp title='Charges' subtext='$2.90' />
            </div>
        </div>

        <h6 align="center" className="subtitle">Beneficiary</h6>
        <div className="container">
            <div className="card mb-4 shadow">
              <ListComp title='Full Name' subtext='BENEF JOHNSZ' />
              <ListComp title='Country' subtext='Zimbabwe' />
              <ListComp title='Mobile' subtext='+263784629765' />
            </div>
        </div>

        <h6 align="center" className="subtitle">Transfer Details</h6>
        <div className="container">
            <div className="card mb-4 shadow">
              <ListComp title='Transfer Type' subtext='Cash Collection' />
              <ListComp title='Collection Point' subtext='ACCESS FOREX OUTLETS' />
              <ListComp title='Payment Method' subtext='Credit/Debit Card' />
            </div>
        </div>

        <div class="alert alert-warning" role="alert">
            <p className="small text-center">Make sure you have entered the correct beneficiary details and amounts before making payment as this will delay the payout. Also note we are unable to accept payments from third parties. The name on the card must match the Access Forex account holder's name. Please proceed to make payment.</p>
        </div>
      
    </>
  );
};

export default Verify;