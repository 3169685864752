import React,{Fragment, useEffect, useContext, useState} from 'react';
import { useHistory} from "react-router-dom";
import { GlobalContext } from '../context';
import Footer from './Footer';
import Header from './Header';
import PhoneInput from 'react-phone-input-2'
import Alert from './ui/Alert';
import ApiErrors from './ApiErrors';
import { Formik, Form, Field, ErrorMessage } from "formik";
import { addBeneficiarySchema } from '../utils/Yup';
import { Apis } from '../utils/Apis';
import { DisplayFormikState } from '../utils/DisplayFormikState';
import Status from './ui/Status';

const AddBeneficiary = () => {

    const {authenticated,loading,setLoading, username, token, status} = useContext(GlobalContext);

    const history = useHistory();

    useEffect(() => {
        
        // Check if user is logged in
        if(authenticated !== "true") history.push('/login');

    }, [])

    

    const [errors, setErrors] = useState([])
    const [apiErrors, setApiErrors] = useState([])

    const initialValues = {
        country: '999',
        phone: '',
        firstName: '',
        middleName: '',
        lastName: '',
        idType: '',
        idDetails: ''
    }

    const submitForm = async (values, {setSubmitting, setErrors, setStatus, resetForm}) => {

        let formValues = values;

        setApiErrors([])
        setLoading(true);

        let data = JSON.stringify({
            username : username,
            token : token,
            country_id : formValues.country,
            first_name : formValues.firstName,
            middle_name : formValues.middleName,
            last_name : formValues.lastName,
            mobile : `+${formValues.phone}`,
            id_type : formValues.idType,
            id_details : formValues.idDetails
        });

        console.log(data)

        await Apis.post(`beneficiaries/new`, data)
        .then(data => {
            if (data.status == "SUCCESS") {

                // Redirect to the Beneficiaries page with flash message
                // history.push('/beneficiaries')
                history.push({
                    pathname: '/beneficiaries',
                    flash: 'Beneficiary Successfully Added',
                });
            } else {
                if ( typeof data.errors !== 'undefined' && typeof data.errors !== null) setApiErrors(data.errors);
                else setApiErrors([data.message]);
            }

        })
        .catch(e => setApiErrors([{message: e.message}]) )
        .finally(() => setLoading(false) );

    }

  return (
    <Fragment>

      <Header hasBack title='New Beneficiary' hasLogout />

        <div className="container">
            <Status />
            { JSON.parse(status).status !== 'blocked' &&
            <div className="row mt-100">
                <div className="col-12 col-md-6 col-lg-4">
                  
                    <ApiErrors errors={apiErrors} />
                    <br/>
                    
                    <Formik
                //   innerRef={ref}
                  initialValues={initialValues}
                  validationSchema={addBeneficiarySchema}
                  onSubmit={submitForm}
                >
                  {(formik) => {
                    const {
                      values,
                      handleChange,
                      handleSubmit,
                      errors,
                      touched,
                      handleBlur,
                      isValid,
                      dirty,
                      setFieldValue
                    } = formik;
                    return (
                    <form onSubmit={handleSubmit} className="form-signin mt-3 " autoComplete="off">

                    <div className="form-group">
                        <label>Country <span className='red-text'>*</span></label>
                        <select 
                        name="country" 
                        value={values.country}
                        onChange={handleChange}
                        className={ `form-control ${ errors.country && touched.country ? "is-invalid" : ( touched.country ? "is-valid" : null ) }`  } 
                        >
                            <option value="99">Zimbabwe</option>
                        </select>
                        {errors.country && touched.country && ( <span className="form-text text-muted small text-danger">{errors.country}</span> )}
                    </div>
                    <br/>
                    <div className="form-group">
                        <label>Mobile Number <span className='red-text'>*</span></label>
                        <PhoneInput
                            name="phone"
                            country={'zw'}
                            value={values.phone}
                            // onChange={handleChange}
                            
                            onChange={(phone) => setFieldValue('phone', phone)}
                            onBlur={handleBlur}
                            // onBlur={(fieldType) => handleBlur(fieldType)}
                            // onChange={phone => setPhone(phone)}
                            placeholder=''
                            // containerClass='phone-input'
                            containerClass={`phone-input`} 
                            autoFormat={false}
                        />
                        {errors.phone && touched.phone && ( <span className="form-text text-muted small text-danger">{errors.phone}</span> )}
                    </div>
                    <br/>
                    <div className="form-group">
                        <label>First Name <span className='red-text'>*</span></label>
                         <input type="text"  
                            name="firstName"
                            className={ `form-control ${ errors.firstName && touched.firstName ? "is-invalid" : ( touched.firstName ? "is-valid" : null ) }`  } 
                            value={values.firstName} 
                            onChange={handleChange} 
                            onBlur={handleBlur}
                            autoComplete="off"
                        />
                        {errors.firstName && touched.firstName && ( <span className="form-text text-muted small text-danger">{errors.firstName}</span> )}
                    </div>
                    <br/>
                    <div className="form-group">
                        <label>Middle Name(s)</label>
                         <input type="text"  
                            name="middleName"
                            value={values.middleName} 
                            onChange={handleChange} 
                            onBlur={handleBlur}
                            className={ `form-control ${ errors.middleName && touched.middleName ? "is-invalid" : ( touched.middleName ? "is-valid" : null ) }`  } 
                            autoComplete="off"
                        />
                        {errors.middleName && touched.middleName && ( <span className="form-text text-muted small text-danger">{errors.middleName}</span> )}
                    </div>
                    <br/>
                    <div className="form-group">
                        <label>Last Name <span className='red-text'>*</span></label>
                         <input type="text"  
                            name="lastName"
                            value={values.lastName} 
                            onChange={handleChange} 
                            onBlur={handleBlur}
                            className={ `form-control ${ errors.lastName && touched.lastName ? "is-invalid" : ( touched.lastName ? "is-valid" : null ) }`  } 
                            autoComplete="off"
                            autoComplete="off"
                        />
                        {errors.lastName && touched.lastName && ( <span className="form-text text-muted small text-danger">{errors.lastName}</span> )}
                    </div>
                    <br/>
                    <div className="form-group">
                        <label>ID Type</label>
                        <select 
                        // className="form-control"
                        name="idType"
                        value={values.idType}
                        onChange={handleChange}
                        className={ `form-control ${ errors.idType && touched.idType ? "is-invalid" : ( touched.idType ? "is-valid" : null ) }`  } 
                        onBlur={handleBlur}
                        >
                            <option value="">Select ID Type</option>
                            <option value="PASSPORT">PASSPORT</option>
                            <option value="DRIVER'S LICENSE">DRIVER'S LICENSE</option>
                            <option value="NATIONAL ID">NATIONAL ID</option>
                        </select>
                        {errors.idType && touched.idType && ( <span className="form-text text-muted small text-danger">{errors.idType}</span> )}
                    </div>
                    <br/>
                    <div className="form-group">
                        <label>ID Number/Details</label>
                         <input type="text"  
                            name="idDetails"
                            value={values.idDetails}
                            onChange={handleChange}
                            className={ `form-control ${ errors.idDetails && touched.idDetails ? "is-invalid" : ( touched.idDetails ? "is-valid" : null ) }`  } 
                            onBlur={handleBlur}
                            autoComplete="off"
                        />
                        {errors.idDetails && touched.idDetails && ( <span className="form-text text-muted small text-danger">{errors.idDetails}</span> )}
                    </div>

                    <br/>

                    <Alert type='warning' 
                    small 
                    text="Please ensure you fill in all mandatory (*) fields and your beneficiary's details entered here matches the details on their government issued ID as they will need to present it during collection. Any incorrect names could cause a delay when collecting funds." 
                    />

                    <br/>
                    {/* <button className="btn btn-default shadow  btn-rounded btn-block btn-lg ">Save</button> */}
                    <button 
                                type='submit'
                                className={ `btn btn-lg btn-block btn-rounded shadow ${ dirty && isValid ? " btn-default " : "btn-secondary" }` }
                                disabled={!(dirty && isValid)}
                                // onClick={() => submitForm()}
                                >Save</button>

                                {/* <DisplayFormikState {...formik} /> */}

                    </form>
                    
                    );
                    }}
                    </Formik>
                  
                </div>
            </div> }

        </div>

        <Footer page='SendMoney' />

    </Fragment>
  );
};

export default AddBeneficiary;