import React, {Fragment} from 'react';
import { useHistory } from "react-router-dom";

export default function Header (props) {
  const { hasBack, hasLogout, title, icon, link } = props;

  const history = useHistory();

  // goBack(){
  //   history.goBack();
  // }

  const headerLink = () => {
    if (link)  history.push(`${link}`)
    else history.goBack()
  }

  const headerIcon = icon ? icon : 'chevron_left';


  return (
    <Fragment>

    <div className="header">
      <div className="row no-gutters">
        
          
          <>
          <div className="col-auto">
            { hasBack &&
              <span onClick={()=>headerLink()} className="btn  btn-link text-dark"><i className="material-icons">{headerIcon}</i></span>
            }
          </div>
          <div className={`col ${hasBack && 'text-center'}`}>
            { title ?
            <h5 className="text-dark" style={{paddingTop:15,paddingLeft:15}}>{title}</h5>
            :
            <img src="/assets/img/af-header-logo.png" alt="" className="header-logo"></img>
            }

          </div>
          <div className="col-auto">
          { hasLogout &&
            <a href="#" data-toggle="modal" data-target="#logout" className="btn  btn-link text-dark position-relative"><i className="material-icons">power_settings_new</i></a>
          }
          </div>
          </>
          
        
      </div>
    </div>


            

    
    </Fragment>
  );
}


