import React, {useEffect, useState, useContext} from 'react';
import { GlobalContext } from '../context';
import { Apis } from '../utils/Apis';
import PulseLoader from "react-spinners/PulseLoader";

const BeneficiaryCountry = (props) => {
  const { id } = props;

  const [beneficiary, setBeneficiary] = useState(null)
  const [loading, setLoading] = useState(null)

  const {username,token} = useContext(GlobalContext)

  useEffect(() => {

    getBeneficiary();

  },[])

  const getBeneficiary =  async () => {
    setLoading(true);
    let data = JSON.stringify({ username : username, token : token});
    await Apis.post(`beneficiaries/${id}`, data)
    .then(data => {
      setLoading(false)
      if (data.status == "SUCCESS") setBeneficiary(data.data)
      else {
          if ( typeof data.errors !== 'undefined' && typeof data.errors !== null) {
          console.log(JSON.stringify(data.errors));
        }else{
          console.log(data.message);
        }
      }
    })
    .catch(e => setLoading(false) )
    .finally(() =>  setLoading(false) );
  }

  
  return (
    <>
    { !loading && beneficiary ? (<span>{beneficiary.country}</span>) : (<PulseLoader color="#fff" loading={true} size={10} />) }
    
    </>
  );
};

export default BeneficiaryCountry;