import React, { createContext, useState, useEffect } from 'react';
import { AppConst } from './utils/AppConst';
import { Apis } from './utils/Apis';
import { useHistory } from "react-router-dom";
import Loading from './components/Loading';
import AsyncLocalStorage from '@createnextapp/async-local-storage'
import usePersistedState from './hooks/usePersistedState';

export const GlobalContext = createContext();

function GlobalContextProvider(props){

    const [authenticated, setAuthentication] =  usePersistedState(AppConst.authenticated, 'false');
    const [token, setToken] =  usePersistedState(AppConst.token, null);
    const [username, setUsername] =  usePersistedState(AppConst.username, null);
    const [country, setCountry] =  usePersistedState(AppConst.country, null);
    const [status, setStatus] =  usePersistedState(AppConst.status, 'active');
    const [loading, setLoading] = useState(false);
   
    const history = useHistory();

    useEffect(() => {
        if ( token ) localStorage.setItem(AppConst.token, token);
        if ( username ) localStorage.setItem(AppConst.username, username);
        if ( country ) localStorage.setItem(AppConst.country, country);
        if ( status ) localStorage.setItem(AppConst.status, status);
        if ( authenticated ) localStorage.setItem(AppConst.authenticated, authenticated);
    }, [authenticated,token,country,status])


    const signIn = async (email,session_token) => {
        setAuthentication('true')
        setUsername(email)
        setToken(session_token)
        history.push('/');
    }

    const signOut = async () => {
        setAuthentication('false')
        setToken(null)
        setUsername(null)
        setCountry(null)
        setStatus(null)
        history.push('/login');
    }

    let globalParams = { 
        authenticated, setAuthentication, 
        token, setToken, 
        loading, setLoading, 
        signIn, signOut, 
        username, setUsername, 
        country, setCountry, 
        status, setStatus 
    }

    return (
        <GlobalContext.Provider value={globalParams}>
        <Loading active={loading} />
        {/* <GlobalContext.Provider value={{ isAuthenticated, setIsAuthenticated, token, setToken, signIn, signOut }}> */}
            {props.children}
        </GlobalContext.Provider>

    );
}

export default GlobalContextProvider;