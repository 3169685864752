import React,{Fragment, useRef, useContext, useState, useEffect} from 'react';
import { useHistory, Link, useLocation } from "react-router-dom";
import { Apis } from '../utils/Apis';
import { GlobalContext } from '../context';

import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import loadjs from "loadjs";
import Header from './Header';

const VerifyRegistration = (props) => {

  const { authenticated, setAuthentication, token, setToken, loading, setLoading, signIn, username } = useContext(GlobalContext);
  
  const history = useHistory();

  const location = useLocation();

  if(authenticated === "true") {
    history.push('/');
  }

  useEffect(() => {
        
      loadjs([
          '/assets/js/jquery-3.3.1.min.js',
          '/assets/js/popper.min.js', 
          '/assets/vendor/bootstrap-4.4.1/js/bootstrap.min.js', 
          '/assets/vendor/swiper/js/swiper.min.js', 
          '/assets/js/main.js', 
      ]);


  }, [])

  const [errors, setErrors] = useState([])
  const [apiErrors, setApiErrors] = useState([])

  const initialValues = {
    email: username,
    verification_code: ''
  }

  const signInSchema = Yup.object().shape({
    email: Yup.string().email().required("Email is required").label('Email Address'),
    verification_code: Yup.string().required().label('Verification Code'),
  });

  const ref = useRef(null);

  const submitForm = async (values) => {

    let formValues = ref.current.values;

    setLoading(true);

    // console.log('Values: ',formValues);

    let data = JSON.stringify({
      email_address : formValues.email,
      verification_code : formValues.verification_code
    });
      

    await Apis.post(`auth/confirm-registration`, data)
    .then(data => {
      if (data.status == "SUCCESS") {

          signIn(formValues.email,data.session_token)

          console.log('success')

      } else {

      console.log(JSON.stringify(data.errors))


        // if (typeof data.errors !== 'undefined') {
        if ( typeof data.errors !== 'undefined' && typeof data.errors !== null) {
          setApiErrors(data.errors);
        }else{
          setApiErrors([data.message]);
          // setErrors(errors.concat([data.message]))
        }

      }
      setLoading(false)

    })
    .catch(e => {
      setApiErrors([e.message])
      setLoading(false)
    })
    .finally(() => {
      setLoading(false);
    });
    

  }

  return (
    <>
    <Header without title='Verification' icon='chevron_left' />
    <Formik
      innerRef={ref}
      initialValues={initialValues}
      validationSchema={signInSchema}
      onSubmit={submitForm}
    >
      {(formik) => {
        const {
          values,
          handleChange,
          handleSubmit,
          errors,
          touched,
          handleBlur,
          isValid,
          dirty
        } = formik;
        return (
      
      <Fragment>
      <div className="row no-gutters login-row">
        <div className="col align-self-center px-3">
            <br/>
            <img src="/assets/img/af-logo.png" alt="logo" className="logo-small" />
            <br/>
            <div class="alert alert-warning" role="alert">
                The verification code has been sent via sms to {location.phone}
            </div>
            {apiErrors.length > 0 && apiErrors.map((error, index) => (
              <div key={index} className="alert alert-danger" role="alert">
                <button type="button" className="close" data-dismiss="alert" aria-label="Close">
                    <span aria-hidden="true">×</span>
                </button>
                {error.error}
              </div>
            ))}
            
            <form  className="form-signin mt-3" autoComplete="off" onSubmit={handleSubmit} >

                <div className="form-group float-label">
                    <input type="text" id="inputEmailAddress" 
                      name="email"
                      value={values.email}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      className={ `form-control form-control-lg ${ errors.email && touched.email ? "is-invalid" : null }`  }
                      autoComplete="off"
                    />
                    <label htmlFor="inputEmailAddress" className="form-control-label">Email Address</label>
                </div>
                {errors.email && touched.email && (
                    <span className="form-text text-muted small text-danger pl-3">{errors.email}</span>
                  )}

                <div className="form-group float-label">
                    <input type="text" 
                      name="verification_code"
                      value={values.password}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      className={ `form-control form-control-lg ${ errors.verification_code && touched.verification_code ? "is-invalid" : null }` } 
                      autoComplete="off"
                    />
                    <label className="form-control-label">Verification Code</label>
                </div>

                {errors.verification_code && touched.verification_code && (
                    <span className="form-text text-muted small text-danger pl-3">{errors.verification_code}</span>
                  )}

                
            </form>               
        </div>
    </div>

      <br/>
      <br/>
    <div className="container">
      <div className="row mx-0">
          <div className="col">
              {/* <a href="/dashboard" className="btn btn-default btn-lg btn-rounded shadow btn-block">Login</a> */}
              <button 
                type="submit"
                className={ `btn btn-lg btn-rounded shadow btn-block ${ dirty && isValid ? " btn-default " : "btn-secondary" }` }
                disabled={!(dirty && isValid)}
                onClick={() => submitForm()}
                
              >Continue</button>
          </div>
      </div>
      <p style={{textAlign:'center',marginTop:15}}>Verification code is case sensitive in all CAPS and sms can take up to 5 minutes.</p>
      <div className="row mx-0">
          <div className="col">
            <Link to='/register' className="mt-4 d-block text-center">Ammend Details</Link>
          </div>
          <div className="col">
            <Link to='#' className="mt-4 d-block text-center">Resend Code</Link>
          </div>
      </div>
      </div>
      </Fragment>

        );
      }}

    </Formik>
    </>
  );
};

export default VerifyRegistration;