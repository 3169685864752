import React from 'react';

const PageNotFound = () => {
  return (
    <div>
      {/* <h>Not Found!</h> */}
    </div>
  );
};

export default PageNotFound;