import React from 'react';

const Options = ({beneficiaries}) => {
  return (
    <div className='mt-4'>

    <div className="form-group">
        <label for="exampleFormControlSelect1">Beneficiary</label>
        <select className="form-control" id="exampleFormControlSelect1">
            <option>Select Beneficiary</option>

            {beneficiaries.map((benef, index) => {
                return(
                    <option key={index} value={benef.beneficiary_id}>{benef.name}</option>
                )
            })}
            
          
        </select>
    </div>
    <br/>
    <div className="form-group">
        <label for="exampleFormControlSelect1">Transfer Type</label>
        <select className="form-control" id="exampleFormControlSelect1">
            <option value="Cash Collection">Cash Collection</option>
        </select>
    </div>
    <br/>
    <div className="form-group">
        <label for="exampleFormControlSelect1">Purpose of Remittance</label>
        <select className="form-control" id="exampleFormControlSelect1">
            <option>Select Purpose of Remittance</option>
            <option value="400 Reversal">400 Reversal</option>
            <option value="401 Gifts">401 Gifts</option>
            <option value="416 Migrant Worker Remittances (excluding Compensation)">416 Migrant Worker Remittances (excluding Compensation)</option>
            <option value="417 (Foreign National Contract Worker Remittances excluding Compensation)">417 (Foreign National Contract Worker Remittances excluding Compensation)</option>
            <option value="Other">Other</option>
        </select>
    </div>
    <br/>
    <div className="form-group">
        <label for="exampleFormControlSelect1">Payment Method</label>
        <select className="form-control" id="exampleFormControlSelect1">
            <option>Select Payment Method</option>
            <option value="Other">Bank Transfer</option>
            <option value="Other">Credit/Debit Card</option>
        </select>
    </div>
      
    </div>
  );
};

export default Options;