import React from 'react';

const ApiErrors = ({errors}) => {
  return (
    <>
      {errors.length !== 0 && errors.map((error, index) => (
        <div key={index} className="alert alert-danger mt-4" role="alert">
          <button type="button" className="close" data-dismiss="alert" aria-label="Close">
              <span aria-hidden="true">×</span>
          </button>
          { typeof error.error !== 'undefined' ? error.error : ( typeof error.message !== 'undefined' ? error.message : error) }
        </div>
      ))}
    </>
  );
};

export default ApiErrors;