import React,{Fragment, useEffect, useContext, useState} from 'react';
import { useHistory, Link, useLocation } from "react-router-dom";
import { GlobalContext } from '../context';
import { Apis } from '../utils/Apis';
import Footer from './Footer';
import Header from './Header';

const Profile = () => {

    const {loading, setLoading, authenticated, username, token, country,setCountry, status, setStatus} = useContext(GlobalContext)

    const [profile, setProfile] = useState([]);
    
    const history = useHistory();
    const location = useLocation();

    useEffect(() => {
        if(authenticated === "false") {
            history.push('/login');
        }

        getProfile()


        console.log(profile)

    }, [])


    const getProfile =  async() => {

      setLoading(true);
      let data = JSON.stringify({ username : username, token : token });
      await Apis.post(`profile`, data)
      .then(data => {
        setLoading(false)
        if (data.status == "SUCCESS") {
            setProfile(data.data)
            setCountry(data.data.country)
            setStatus(JSON.stringify({status:data.data.status,type:data.data.type}))
        }
        else {
            if ( typeof data.errors !== 'undefined' && typeof data.errors !== null) console.log(JSON.stringify(data.errors));
          else console.log(data.message);
        }
      })
      .catch(e => setLoading(false) )
      .finally(() =>  setLoading(false) );
    }


  return (
    <Fragment >

      <Header hasBack hasLogout title='Profile' link='/' />

        { !loading && profile.length !== 0 ?  
        <>

        <div className="container">
        { location.flash ? (
              <div className="alert alert-success mt-4" role="alert">
                <button type="button" className="close" data-dismiss="alert" aria-label="Close">
                    <span aria-hidden="true">×</span>
                </button>
                { location.flash }
              </div>
            ) : null }
            <div className="card bg-template-custom shadow mt-4 h-190">
                <div className="card-body">
                    <div className="row">
                        
                        <div className="col pl-4 text-center">
                            <h3 className="mb-1">{profile.firstname} {profile.lastname}</h3>
                            <p className="text-mute small">Member Since: {profile.creation_date}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div className="container top-100">
    
        <div className="card mb-4 shadow">
                                
                <div className="card-body border-bottom">
                    <div className="row">
                        <div className="col">
                            <p className="font-weight-bold">Member ID</p>
                        </div>
                        <div className="col text-right">
                            <p>{profile.remitter_id}</p>
                        </div>
                    </div>
                </div>
                                
                <div className="card-body border-bottom">
                    <div className="row">
                        <div className="col">
                            <p className="font-weight-bold">First Name</p>
                        </div>
                        <div className="col text-right">
                            <p>{profile.firstname}</p>
                        </div>
                    </div>
                </div>
                                
                <div className="card-body border-bottom">
                    <div className="row">
                        <div className="col">
                            <p className="font-weight-bold">Middle Name (s)</p>
                        </div>
                        <div className="col text-right">
                            <p>{profile.middlename}</p>
                        </div>
                    </div>
                </div>
                                
                <div className="card-body border-bottom">
                    <div className="row">
                        <div className="col">
                            <p className="font-weight-bold">Last Name</p>
                        </div>
                        <div className="col text-right">
                            <p>{profile.lastname}</p>
                        </div>
                    </div>
                </div>
                                
                <div className="card-body border-bottom">
                    <div className="row">
                        <div className="col">
                            <p className="font-weight-bold">Date of Birth</p>
                        </div>
                        <div className="col text-right">
                            <p>{profile.dob}</p>
                        </div>
                    </div>
                </div>
                                
                <div className="card-body border-bottom">
                    <div className="row">
                        <div className="col">
                            <p className="font-weight-bold">Nationality</p>
                        </div>
                        <div className="col text-right">
                            <p>{profile.nationality}</p>
                        </div>
                    </div>
                </div>
                                
                <div className="card-body border-bottom">
                    <div className="row">
                        <div className="col">
                            <p className="font-weight-bold">Gender</p>
                        </div>
                        <div className="col text-right">
                            <p>{profile.gender}</p>
                        </div>
                    </div>
                </div>
                                
            </div>
        </div>

        <h6 align="center" className="subtitle">Contact Information</h6>

        <div className="container">
            <div className="card mb-4 shadow">
                               
                <div className="card-body border-bottom">
                    <div className="row">
                        <div className="col">
                            <p className="font-weight-bold">Email Address</p>
                        </div>
                        <div className="col text-right">
                            <p>{profile.email}</p>
                        </div>
                    </div>
                </div>
                                
                <div className="card-body border-bottom">
                    <div className="row">
                        <div className="col">
                            <p className="font-weight-bold">Address Line 1</p>
                        </div>
                        <div className="col text-right">
                            <p>{profile.address1}</p>
                        </div>
                    </div>
                </div>
                                
                <div className="card-body border-bottom">
                    <div className="row">
                        <div className="col">
                            <p className="font-weight-bold">Address Line 2</p>
                        </div>
                        <div className="col text-right">
                            <p>{profile.address2}</p>
                        </div>
                    </div>
                </div>
                                
                <div className="card-body border-bottom">
                    <div className="row">
                        <div className="col">
                            <p className="font-weight-bold">City</p>
                        </div>
                        <div className="col text-right">
                            <p>{profile.city}</p>
                        </div>
                    </div>
                </div>
                                
                <div className="card-body border-bottom">
                    <div className="row">
                        <div className="col">
                            <p className="font-weight-bold">State/Province</p>
                        </div>
                        <div className="col text-right">
                            <p>{profile.state}</p>
                        </div>
                    </div>
                </div>
                                
                <div className="card-body border-bottom">
                    <div className="row">
                        <div className="col">
                            <p className="font-weight-bold">Country</p>
                        </div>
                        <div className="col text-right">
                            <p>{profile.country}</p>
                        </div>
                    </div>
                </div>
                                
                <div className="card-body border-bottom">
                    <div className="row">
                        <div className="col">
                            <p className="font-weight-bold">Mobile Number</p>
                        </div>
                        <div className="col text-right">
                            <p>{profile.mobile}</p>
                        </div>
                    </div>
                </div>
                            </div>
        </div>
        

        <h6 align="center" className="subtitle">Identification Documents</h6>

        <div className="container">
            <div className="card shadow-sm border-0 mb-3 wizard">
                <div className="card-header p-0">
                    <ul className="nav nav-tabs tabs-md nav-justified" role="tablist">
                        <li role="presentation" className="nav-item">
                            <a href="#step13" className="nav-link border-primary active" data-toggle="tab" aria-controls="step13" role="tab" title="Photo ID" aria-selected="true">Photo ID</a>
                        </li>
                        <li role="presentation" className="nav-item">
                            <a href="#step23" className="nav-link border-primary" data-toggle="tab" aria-controls="step23" role="tab" title="Selfie" aria-selected="false">Selfie</a>
                        </li>
                      
                    </ul>
                </div>
                <div className="card-body">
                    <div className="tab-content">
                        <div className="tab-pane active" role="tabpanel" id="step13">
                            <div className="text-center">
                                <div className="figure-profile shadow my-4">
                                    <figure><img src={`data:image/jpg;base64,${profile.documents[0].id_scan1_content}`} alt=""/></figure>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12 px-0">
                                    <div className="list-group list-group-flush">
                                        <a href="#" className="list-group-item">
                                            <h6 className="text-dark mb-0 font-weight-normal"><strong>Type</strong> <i className="float-right">{profile.documents[0].id_type}</i></h6>
                                        </a>
                                       
                                        <a href="#" className="list-group-item">
                                            <h6 className="text-dark mb-0 font-weight-normal"><strong>Number/Details</strong> <i className="float-right">{profile.documents[0].id_details}</i></h6>
                                        </a>
                                       
                                        <a href="#" className="list-group-item">
                                            <h6 className="text-dark mb-0 font-weight-normal"><strong>Issue Place</strong> <i className="float-right">{profile.documents[0].id_issue_place}</i></h6>
                                        </a>
                                       
                                        <a href="#" className="list-group-item">
                                            <h6 className="text-dark mb-0 font-weight-normal"><strong>Issue Country</strong> <i className="float-right">{profile.documents[0].id_issue_country}</i></h6>
                                        </a>
                                       
                                        <a href="#" className="list-group-item">
                                            <h6 className="text-dark mb-0 font-weight-normal"><strong>Expiry Date</strong> <i className="float-right">{profile.documents[0].id_expiry}</i></h6>
                                        </a>

                                        <a href="#" className="list-group-item">
                                            <h6 className="text-dark mb-0 font-weight-normal"><strong>Verification</strong> <i className="float-right">{profile.documents[0].id_verified}</i></h6>
                                        </a>
                                       
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="tab-pane" role="tabpanel" id="step23">
                            <div className="text-center">
                                <div className="figure-profile shadow my-4">
                                    <figure><img src={`data:image/jpg;base64,${profile.documents[1].id_scan1_content}`} alt=""/></figure>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12 px-0">
                                    <div className="list-group list-group-flush">
                                        <a href="#" className="list-group-item">
                                            <h6 className="text-dark mb-0 font-weight-normal"><strong>Type</strong> <i className="float-right">{profile.documents[1].id_type}</i></h6>
                                        </a>
                                       
                                        <a href="#" className="list-group-item">
                                            <h6 className="text-dark mb-0 font-weight-normal"><strong>Number/Details</strong> <i className="float-right">{profile.documents[1].id_details}</i></h6>
                                        </a>
                                       
                                        <a href="#" className="list-group-item">
                                            <h6 className="text-dark mb-0 font-weight-normal"><strong>Issue Place</strong> <i className="float-right">{profile.documents[1].id_issue_place}</i></h6>
                                        </a>
                                       
                                        <a href="#" className="list-group-item">
                                            <h6 className="text-dark mb-0 font-weight-normal"><strong>Issue Country</strong> <i className="float-right">{profile.documents[1].id_issue_country}</i></h6>
                                        </a>
                                       
                                        <a href="#" className="list-group-item">
                                            <h6 className="text-dark mb-0 font-weight-normal"><strong>Expiry Date</strong> <i className="float-right">{profile.documents[1].id_expiry}</i></h6>
                                        </a>

                                        <a href="#" className="list-group-item">
                                            <h6 className="text-dark mb-0 font-weight-normal"><strong>Verification</strong> <i className="float-right">{profile.documents[1].id_verified}</i></h6>
                                        </a>
                                       
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="clearfix"></div>
                    </div>
                </div>

            </div>
        </div>

        

        <div className="container">
            <div className="row mx-0">
                <div className="col">
                    <Link to="/change-password" className="btn btn-default btn-sm btn-rounded shadow btn-block">Change Password</Link>
                </div>
                <div className="col">
                    <Link to="/profile/update" className="btn btn-default btn-sm btn-rounded shadow btn-block">Update Profile</Link>
                </div>
            </div>
            {/* <button type="button" className="shadow mr-2 btn btn-default rounded mb-2">Edit Profile</button> */}
        </div>

        </>

        : null }
        
        
        <Footer page='Profile'/>

    </Fragment>
  );
};

export default Profile;