import React,{useEffect,useState, useContext} from 'react';
import { useHistory, Link } from "react-router-dom";
import { GlobalContext } from '../context';
import { Apis } from '../utils/Apis';
import PulseLoader from "react-spinners/PulseLoader";

const Sidebar = () => {

    const {authenticated, username, token} = useContext(GlobalContext)

    const [loading, setLoading] = useState(false);
    const [profile, setProfile] = useState([]);
    

    useEffect(() => {

        if(authenticated === "true") {
            getProfile()
        }

    }, [])


    const getProfile =  async() => {

      setLoading(true);
      let data = JSON.stringify({ username : username, token : token });
      await Apis.post(`profile`, data)
      .then(data => {
        setLoading(false)
        if (data.status == "SUCCESS") setProfile(data.data)
        else {
            if ( typeof data.errors !== 'undefined' && typeof data.errors !== null) console.log(JSON.stringify(data.errors));
          else console.log(data.message);
        }
      })
      .catch(e => setLoading(false) )
      .finally(() =>  setLoading(false) );
    }

  return (
    <>

    <div className="sidebar">
        <div className="mt-4 mb-3">
        { !loading && profile.length > 0 ? ( <center><PulseLoader color="#fff" loading={true} size={10} /></center> ) : 
            <div className="row">
                <div className="col-auto">
                    <figure className="avatar avatar-60 border-0"><img src="/assets/img/avatar.png" alt="" /></figure>
                </div>
                <div className="col pl-0 align-self-center">
                    <h5 className="mb-1">{profile.firstname} {profile.lastname}</h5>
                    <p className="text-mute small">{profile.country}, {profile.remitter_id}</p>
                </div>
            </div>
        }
        </div>
        <div className="row">
            <div className="col">
                <div className="list-group main-menu">
                    <Link to="/" className="list-group-item list-group-item-action"><i className="material-icons icons-raised">home</i>Home</Link>
                    <Link to="/send-money" className="list-group-item list-group-item-action"><i className="material-icons icons-raised">send</i>Send Money</Link>
                    <Link to="/beneficiaries" className="list-group-item list-group-item-action"><i className="material-icons icons-raised">people</i>Beneficiaries</Link>
                    <Link to="/transactions" className="list-group-item list-group-item-action"><i className="material-icons icons-raised">event</i>Transactions</Link>
                    <Link to="/profile" className="list-group-item list-group-item-action"><i className="material-icons icons-raised">account_circle</i>Profile</Link>
                    <a href="/logout" className="list-group-item list-group-item-action"><i className="material-icons icons-raised bg-danger">power_settings_new</i>Logout</a>
                </div>
            </div>
        </div>
    </div>
    {/* <a href="#" className="closesidemenu"><i className="material-icons icons-raised bg-dark ">close</i></a> */}
    <span className="closesidemenu"><i className="material-icons icons-raised bg-dark ">close</i></span>
      
    </>
  );
};

export default Sidebar;