import React from 'react';

export default function Loading(props) {
  const { active } = props;
  return (
    <>
    
    { active ?
    <div className="row no-gutters vh-100 loader-screen">
        <div className="col align-self-center text-white text-center">
            <img src="/assets/img/af-icon.png" alt="logo" />
            <h1 className="mt-3"><span className="font-weight-light ">Access</span> Forex</h1>
            <p className="text-mute text-uppercase small">Loading</p>
            <div className="laoderhorizontal">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
            </div>
        </div>
    </div>
    : null }

    </>
  );
}