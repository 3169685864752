const URIs = () => {
  let endpoints = {
    dev: "http://af-api-ci.local/v1/",
    prod: "https://afapi.growthsense.co.za/v1/"
  };
  return endpoints.prod;
};

const setHeaders = (token = null, contentType = null) => {
  let headers = {
    Accept: "application/json"
  }
  if(contentType) headers["Content-Type"] = contentType;
  if(token) headers['Authorization'] = `Bearer ${token}`;

  return headers;
}

export const Apis = {
  get: (endpoint, token) => {
    return new Promise((resolve, reject) => {
      fetch(`${URIs()}${endpoint}`, {
        method: "GET",
        // headers: setHeaders(token, "application/json")
        headers: setHeaders(token)
      }).then(async response => {
          const json = response.json();
          if (response.ok) {
            resolve(json);
          } else {
            const err = await json;
            return reject(err);
          }
      })
      // .catch(e => reject(new Error(e.message)));
      .catch(e => reject(new Error(e.message === 'Failed to fetch' ? 'Could not connect to the server.' : e.message)));
    });
  },
  post: (endpoint, data, token) => {
    return new Promise(async (resolve, reject) => {
      await fetch(`${URIs()}${endpoint}`, {
        method: "POST",
        // headers: setHeaders(token),
        body: data
      })
        .then(async response => {
          const json = response.json();
          if (response.ok) {
            resolve(json);
          } else {
            const err = await json;
            return reject(err);
          }
        })
        // .catch(e => reject(new Error(e.message)));
        .catch(e => reject(new Error(e.message === 'Failed to fetch' ? 'Could not connect to the server.' : e.message )));
    });
  },
  delete: (endpoint, token) => {
    return new Promise(async (resolve, reject) => {
      await fetch(`${URIs()}${endpoint}`, {
        method: "DELETE",
        // headers: setHeaders(token)
      })
        .then(async response => {
          const json = response.json();
          if (response.ok) {
            resolve(json);
          } else {
            const err = await json;
            return reject(err);
          }
        })
        // .catch(e => reject(new Error(e.message)));
        .catch(e => reject(new Error(e.message === 'Failed to fetch' ? 'Could not connect to the server.' : e.message)));
    });
  }
};
