import React from 'react';

export default function FormValidation(props) {
  const { message } = props;
  return (
    <>
    {message.length > 0 &&
        <div style={{fontSize:12}} className="alert alert-danger">
          {message}
        </div>
    }
    </>
  );
}