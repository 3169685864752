import React,{Fragment, useEffect, useContext, useState} from 'react';
import { useHistory} from "react-router-dom";
import { GlobalContext } from '../context';
import { Apis } from '../utils/Apis';
import Footer from './Footer';
import Header from './Header';
import Amounts from './send-money/Amounts';
import Options from './send-money/Options';
import Verify from './send-money/Verify';
import Status from './ui/Status';

const SendMoney = () => {

    const {authenticated,status,username, token,loading, setLoading} = useContext(GlobalContext)

    const [beneficiaries, setBeneficiaries] = useState([])

    const [step, setStep] = useState(1)

    const history = useHistory();
    useEffect(() => {
        if(!authenticated) {
            history.push('/login');
        }

        if( JSON.parse(status).status !== 'blocked' ) getBeneficiaries()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const getBeneficiaries =  async() => {
      setLoading(true);
      let data = JSON.stringify({ username : username, token : token });
      console.log(data)
      await Apis.post(`beneficiaries`, data)
      .then(data => {
        setLoading(false)
        console.log(data)
        if (data.status == "SUCCESS") setBeneficiaries(data.data)
        else console.log(data.message);
      })
      .catch(e => setLoading(false) )
      .finally(() =>  setLoading(false) );
    }


  return (
    <Fragment >

      <Header hasLogout hasBack title='Send Money' />

        <div className="container">
            <Status />
            { JSON.parse(status).status !== 'blocked' &&
            <>
            <div className="row mt-100">
                <div className="col-12 col-md-6 col-lg-4">

                    { step === 1 && <Options beneficiaries={beneficiaries} /> }
                    { step === 2 && <Amounts /> }
                    { step === 3 && <Verify /> }
                    
                    {/* <Amounts /> */}
                    {/* <Verify /> */}
                    
                </div>
            </div>
            <br/>
            <br/>
            <button onClick={()=>setStep(2)} className="btn btn-default shadow  btn-rounded btn-block btn-lg ">Next</button>
            {/* <button onClick={()=>setStep((prevState) => prevState + 1 )} className="btn btn-default shadow  btn-rounded btn-block btn-lg ">Next</button> */}
            </>
            }
            
            

        </div>

        
        
        <Footer/>

    </Fragment>
  );
};

export default SendMoney;