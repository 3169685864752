import React,{Fragment,useState, useEffect, useContext } from 'react';
import { useHistory, Link } from "react-router-dom";
import { GlobalContext } from '../context';
import { Apis } from '../utils/Apis';
import BeneficiaryCountry from './BeneficiaryCountry';
import Footer from './Footer';
import Header from './Header';
import Loading from './Loading';


const SingleTransaction = ({match}) => {
    
    const [transaction, setTransaction] = useState(null)
    
    const {authenticated,loading, setLoading, username, token} = useContext(GlobalContext)
    const history = useHistory();


    useEffect(() => {

        if ( authenticated === "false" ) {
            history.push('/login');
        }

        if (match === undefined) {
            history.push('/transactions');
        }
        
        getTransaction(match.params.slug)

    }, [])


    const getTransaction =  async  (slug) => {
      setLoading(true);
      let data = JSON.stringify({ username : username, token : token});
      await Apis.post(`transactions/${slug}`, data)
      .then(data => {
        setLoading(false)
        if (data.status == "SUCCESS") setTransaction(data.data)
        else {
            if ( typeof data.errors !== 'undefined' && typeof data.errors !== null) {
            console.log(JSON.stringify(data.errors));
          }else{
            console.log(data.message);
          }
        }
      })
      .catch(e => setLoading(false) )
      .finally(() =>  setLoading(false) );
    }


  return (
    <Fragment >

    <Loading active={loading}/>

    <Header withMenu />

    <div className="container">
        <div className="row">
            <div className="col text-center">
                <h4 className="mt-5" align="center">Transaction Details</h4>
                
            </div>
        </div>
        
    </div>

    { !loading && transaction ?  
    <>
    <div className="container">
            <div className="row text-center mt-4">
                <div className="col-6 col-md-3">
                    <div className="card shadow bg-template-custom border-0 mb-3">
                        <div className="card-body">
                            <h3 className="mt-3 mb-0 font-weight-normal">{transaction.send_currency} {transaction.remitter_pay}</h3>
                            <p className="small">Total to Pay</p>
                        </div>
                    </div>
                </div>
                <div className="col-6 col-md-3">
                    <div className="card bg-template-custom shadow border-0 mb-3">
                        <div className="card-body">
                            <h3 className="mt-3 mb-0 font-weight-normal">{transaction.receive_currency} {transaction.receive_amount}</h3>
                            <p className="small">Receiving Amount</p>
                        </div>
                    </div>
                </div>
            </div>
    </div>

    <div className="container mt-2 top-30">
        <div className="card mb-4 shadow">
            <div className="card-body bg-none py-3">
                <div className="row">
                    <div className="col text-right">
                        <p>{transaction.send_currency}1 = {transaction.receive_currency}{transaction.rate}<br/><small className="text-mute font-weight-bold">Rate</small></p>
                    </div>
                    <div className="col border-left-dotted">
                        <p>{transaction.send_currency}{transaction.fees}<br/><small className="text-mute font-weight-bold">Charges</small></p>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div className="container">

                    <div className="card shadow border-0 mb-3">
            <div className="card-body">
                <div className="row align-items-center">
                    <div className="col">
                        <h6 className="font-weight-bold mb-1">Date</h6>
                    </div>
                    <div className="col-auto align-self-center">
                        <h6 className="font-weight-normal mb-1">{transaction.creation_date}</h6>
                    </div>
                </div>
            </div>
        </div>
                    <div className="card shadow border-0 mb-3">
            <div className="card-body">
                <div className="row align-items-center">
                    <div className="col">
                        <h6 className="font-weight-bold mb-1">Reference</h6>
                    </div>
                    <div className="col-auto align-self-center">
                        <h6 className="font-weight-normal mb-1">{transaction.trans_ref}</h6>
                    </div>
                </div>
            </div>
        </div>
                    <div className="card shadow border-0 mb-3">
            <div className="card-body">
                <div className="row align-items-center">
                    <div className="col">
                        <h6 className="font-weight-bold mb-1">Status</h6>
                    </div>
                    <div className="col-auto align-self-center">
                        <h6 className="font-weight-normal mb-1">{transaction.status}</h6>
                    </div>
                </div>
            </div>
        </div>
                    <div className="card shadow border-0 mb-3">
            <div className="card-body">
                <div className="row align-items-center">
                    <div className="col">
                        <h6 className="font-weight-bold mb-1">Payment Method</h6>
                    </div>
                    <div className="col-auto align-self-center">
                        <h6 className="font-weight-normal mb-1">{transaction.payment_method_name}</h6>
                    </div>
                </div>
            </div>
        </div>
                    <div className="card shadow border-0 mb-3">
            <div className="card-body">
                <div className="row align-items-center">
                    <div className="col">
                        <h6 className="font-weight-bold mb-1">Transfer Type</h6>
                    </div>
                    <div className="col-auto align-self-center">
                        <h6 className="font-weight-normal mb-1">{transaction.trans_type}</h6>
                    </div>
                </div>
            </div>
        </div>
                    <div className="card shadow border-0 mb-3">
            <div className="card-body">
                <div className="row align-items-center">
                    <div className="col">
                        <h6 className="font-weight-bold mb-1">Collection Point</h6>
                    </div>
                    <div className="col-auto align-self-center">
                        <h6 className="font-weight-normal mb-1">{transaction.collection_point}</h6>
                    </div>
                </div>
            </div>
        </div>
                    <div className="card shadow border-0 mb-3">
            <div className="card-body">
                <div className="row align-items-center">
                    <div className="col">
                        <h6 className="font-weight-bold mb-1">Collection PIN</h6>
                    </div>
                    <div className="col-auto align-self-center">
                        <h6 className="font-weight-normal mb-1">{transaction.collection_pin}</h6>
                    </div>
                </div>
            </div>
        </div>
        
        <h5 className="subtitle mb-1" align="center">Beneficiary Details</h5>
        <Link to={"/beneficiaries/"+transaction.benef_id}>
        <div className="card bg-template-custom shadow mt-4">
            <div className="card-body">
                <div className="row">
                    <div className="col-auto">
                        <figure className="avatar avatar-60"><img src="/assets/img/avatar.png" alt=""/></figure>
                    </div>
                    
                    <div className="col pl-0 align-self-center">
                        <h5 className="mb-1">{transaction.benef_name}</h5>
                        <p><img className="flag-img" style={{marginTop:-3}} src="https://remit.accessforex.com/admin/images/flags/zw.png" alt=""/>  <BeneficiaryCountry id={transaction.benef_id} /> <small className="float-right text-mute">{transaction.benef_mobile}</small></p>
                    </div>
                </div>
            </div>
        </div>
        </Link>
    
    </div> 
    
    </>

    : null }


    <Footer page="Transactions" />

    </Fragment>
  );
};

export default SingleTransaction;