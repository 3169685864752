import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';

// import { Offline, Online } from "react-detect-offline";
import { BrowserRouter as Router } from "react-router-dom";
import GlobalContextProvider from './context';

ReactDOM.render(
  <React.Fragment>
  {/* <React.StrictMode> */}
    <Router>
      <GlobalContextProvider>
        <App />
      </GlobalContextProvider>
    </Router>
  {/* </React.StrictMode> */}
  </React.Fragment>,
  document.getElementById('root')
);
