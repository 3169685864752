import React, {useEffect, useState, useContext} from 'react';
import { GlobalContext } from '../context';
import { Apis } from '../utils/Apis';
import PulseLoader from "react-spinners/PulseLoader";

const TransactionAmount = (props) => {
  const { id } = props;

  const [transaction, setTransaction] = useState(null)
  const [loading, setLoading] = useState(null)

  const {username,token} = useContext(GlobalContext)

  useEffect(() => {

    getTransaction();

  },[])

  const getTransaction =  async () => {
    setLoading(true);
    let data = JSON.stringify({ username : username, token : token});
    await Apis.post(`transactions/${id}`, data)
    .then(data => {
      setLoading(false)
      if (data.status == "SUCCESS") setTransaction(data.data)
      else {
          if ( typeof data.errors !== 'undefined' && typeof data.errors !== null) {
          console.log(JSON.stringify(data.errors));
        }else{
          console.log(data.message);
        }
      }
    })
    .catch(e => setLoading(false) )
    .finally(() =>  setLoading(false) );
  }

  
  return (
    <>
    { !loading && transaction ? (<span>{transaction.send_currency} {transaction.remitter_pay}</span>) : (<PulseLoader color="#3D376B" loading={true} size={10} />) }
    
    </>
  );
};

export default TransactionAmount;