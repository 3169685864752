import React from 'react';
import ReactFlagsSelect from 'react-flags-select';

const Amounts = () => {
  return (
    <div className='mt-4'>

    <div className="form-group">
        <label>Sending From</label>
        <ReactFlagsSelect
            name="country"
            selected='ZA'
            // onSelect={code => setFieldValue('country', code,true)}
            // onSelect={handleChange}
            // onBlur={handleBlur}
            // countries={countryOptions}
            countries={["ZA"]}
            // countries={["GB", "ZA"]}
            // placeholder="Select Country"
            selectedSize={10}
            optionsSize={14}
            // className="select-flags"
            className={ `select-flags`  } 
        />
    </div>
    <div className="form-group">
        <label>Sending To</label>
        <ReactFlagsSelect
            name="country"
            selected='ZW'
            // onSelect={code => setFieldValue('country', code,true)}
            // onSelect={handleChange}
            // onBlur={handleBlur}
            // countries={countryOptions}
            countries={["ZW"]}
            // countries={["GB", "ZA"]}
            // placeholder="Select Country"
            selectedSize={10}
            optionsSize={14}
            // className="select-flags"
            className={ `select-flags`  } 
        />
    </div>
    <div className="form-group">
        <label>Receiving Currency</label>
        <select className="form-control">
            <option value="">USD Cash</option>
            <option value="">Rands Cash</option>
        </select>
    </div>
    <br/>
    <div className="form-group">
      <label>Sending Amount</label>
      <div className="input-group mb-3">
          <div className="input-group-prepend">
              <span className="input-group-text">ZAR</span>
          </div>
          <input type="text" className="form-control" placeholder='0.00'/>
      </div>
    </div>
    <br/>
    <div className="form-group">
      <label>Receiving Amount</label>
      <div className="input-group mb-3">
          <div className="input-group-prepend">
              <span className="input-group-text">USD</span>
          </div>
          <input type="text" className="form-control" placeholder='0.00'/>
      </div>
    </div>
    <div className="form-group">
        <label>Promo Code</label>
            <input type="text"  
            name="firstName"
            className={ `form-control` } 
            value='' 
            autoComplete="off"
        />
    </div>
    <br/>
    <center>
    <button className="btn btn-default shadow calc-btn btn-rounded btn-sm ">Calculate</button>
    </center>

    {/* <div className='calc-results'>
      <div className="alert alert-primary" role="alert">
            Your sending amount has been adjusted because we can only pay out in multiples of <span id="round_amount">$10</span>
        </div>

            <div className="card mb-4 shadow">
                <div className="card-body border-bottom">
                      <div className="row">
                          <div className="col">
                              <p>Rate: </p>
                          </div>
                          <div className="col text-right">
                              <p id="show_exchange_rate" className="text-muted"><b>$1 = R<span id="exchange_rate">15.4900</span></b></p>
                          </div>
                      </div>
                </div>
                <div className="card-body border-bottom">
                      <div className="row">
                          <div className="col">
                              <p>Charges: </p>
                          </div>
                          <div className="col text-right">
                              <p id="show_exchange_rate" className="text-muted"><b>R<span id="exchange_rate">19.42</span></b></p>
                          </div>
                      </div>
                </div>
                <div className="card-body border-bottom">
                      <div className="row">
                          <div className="col">
                              <p>Total To Pay: </p>
                          </div>
                          <div className="col text-right">
                              <p  id="show_exchange_rate" className="text-muted"><b>R<span id="exchange_rate">174.32</span></b></p>
                          </div>
                      </div>
                </div>
                
            </div>
    </div> */}
      
    </div>
  );
};

export default Amounts;