const Alert = ({type,title,small,centered,text}) => {
  return (
    <>
      <div class={`alert alert-${type}`} role="alert">
            {title && <h4 class={`alert-heading ${centered && 'text-center'}`}>{title}</h4>}
            <p className={`${small && 'small'} ${centered && 'text-center'}`}>{text}</p>
      </div>
    </>
  );
};

export default Alert;