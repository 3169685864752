import React, {useState, useContext, Fragment, useRef} from 'react';
import { useHistory, Link } from "react-router-dom";
import Header from './Header';
import { YearPicker, MonthPicker, DayPicker } from 'react-dropdown-date';
import { GlobalContext } from '../context';

import { Apis } from '../utils/Apis';

import { Formik, Form, Field, ErrorMessage } from "formik";

import { isEmpty } from '../utils/AppConst';
import { resetPasswordSchema } from '../utils/Yup';
import ApiErrors from './ApiErrors';

const ResetPassword = () => {

    const { username, token, loading, setLoading} = useContext(GlobalContext);

    const history = useHistory();

    const [errors, setErrors] = useState([])
    const [apiErrors, setApiErrors] = useState([])

    const initialValues = {
        token: '',
        newPassword: '',
        confirmPassword: '',
    }

    const ref = useRef(null);

    const submitForm = async () => {

        let formValues = ref.current.values;

        setLoading(true);

        let data = JSON.stringify({
            token : formValues.token,
            username : username,
            new_password : formValues.newPassword,
        });

        await Apis.post(`auth/reset-password`, data)
        .then(data => {
        // setLoading(false)
        console.log(data);
        if (data.status == "SUCCESS") {

            
            
           
            // setUsername(formValues.email);

            // history.push('/reset-password');
            history.push({
            pathname: '/login',
            flash: 'Your password has been changed.',
            });
            
        } else {

            if ( typeof data.errors !== 'undefined' && typeof data.errors !== null) {
              setApiErrors(data.errors);
            }else{
              setApiErrors([data.message]);
            }

        }

        })
        .catch(e => setApiErrors([{message: e.message}]) )
        .finally(() => setLoading(false) );

    }

  

  return (
    <Fragment>

    <Header title='Reset Password' hasBack link='/forgot-password' icon='chevron_left' />

    <div className="row no-gutters login-row">
            <div className="col px-3 text-center">
                <br/>
                <img src="/assets/img/af-logo.png" alt="logo" className="logo-small" />


                <ApiErrors errors={apiErrors} />

                <Formik
                  innerRef={ref}
                  initialValues={initialValues}
                  validationSchema={resetPasswordSchema}
                  // onSubmit={submitForm}
                >
                  {(formik) => {
                    const {
                      values,
                      handleChange,
                      handleSubmit,
                      errors,
                      touched,
                      handleBlur,
                      isValid,
                      dirty,
                      setFieldValue
                    } = formik;
                    return (
                <form onSubmit={handleSubmit} className="form-signin mt-3 " autoComplete="off">
                    <div className="form-group">
                        <label>Token *</label>
                        <input type="text" 
                          name="token"
                          className={ `form-control form-control ${ errors.token && touched.token ? "is-invalid" : ( touched.token ? "is-valid" : null ) }`  } 
                          value={values.token} 
                          onChange={handleChange} 
                          onBlur={handleBlur}
                          autoComplete="off"
                        />
                        {errors.token && touched.token && ( <span className="form-text text-muted small text-danger">{errors.token}</span> )}
                    </div>
                    <div className="form-group">
                        <label>New Password</label>
                        <input type="text" 
                          name="newPassword"
                          className={ `form-control form-control ${ errors.newPassword && touched.newPassword ? "is-invalid" : ( touched.newPassword ? "is-valid" : null ) }`  } 
                          value={values.newPassword} 
                          onChange={handleChange} 
                          onBlur={handleBlur}
                          autoComplete="off"
                        />
                        {errors.newPassword && touched.newPassword && ( <span className="form-text text-muted small text-danger">{errors.newPassword}</span> )}
                    </div>
                    <div className="form-group">
                        <label>Confirm Password *</label>
                        <input type="text" 
                          name="confirmPassword"
                          className={ `form-control form-control ${ errors.confirmPassword && touched.confirmPassword ? "is-invalid" : ( touched.confirmPassword ? "is-valid" : null ) }`  } 
                          value={values.confirmPassword} 
                          onChange={handleChange} 
                          onBlur={handleBlur}
                          autoComplete="off"
                        />
                        {errors.confirmPassword && touched.confirmPassword && ( <span className="form-text text-muted small text-danger">{errors.confirmPassword}</span> )}
                    </div>
                  
                    <br/>

                    <div className="row mx-0">
                        <div className="col">
                            <button 
                              className={ `btn btn-default btn-lg btn-rounded shadow btn-block ${ dirty && isValid ? " btn-default " : "btn-secondary" }` }
                              disabled={!(dirty && isValid)}
                              onClick={() => submitForm()}
                              >Submit</button>
                        </div>
                    </div>
                    <div className="row mx-0">
                        <div className="col">
                            <Link to='/register' className="mt-4 d-block text-center">Register Now</Link>
                        </div>
                        <div className="col">
                            <Link to='/login' className="mt-4 d-block text-center">Sign In</Link>
                        </div>
                    </div>
                
                </form>
                );
                }}

              </Formik>               
            </div>
        </div>

        
     
    </Fragment>
    
  );
};

export default ResetPassword;