import * as Yup from "yup";

const forgotPasswordSchema = Yup.object().shape({
    email: Yup.string().email('Invalid email address').required("Email is required").label('Email Address'),
    day: Yup.number().required().label('Day'),
    month: Yup.number().required().label('Month'),
    year: Yup.number().required().label('Year'),
    
});

const resetPasswordSchema = Yup.object().shape({
    token: Yup.string().required().label('Token'),
    newPassword: Yup.string().required().label('New Password'),
    confirmPassword: Yup.string().when("newPassword", {
    is: val => (val && val.length > 0 ? true : false),
      then: Yup.string().oneOf(
        [Yup.ref("newPassword")],
        "Both passwords need to be the same"
      )
    })
    
});

const changePasswordSchema = Yup.object().shape({
    currentPassword: Yup.string().required().label('Current Password'),
    newPassword: Yup.string().required().label('New Password'),
    confirmPassword: Yup.string().when("newPassword", {
    is: val => (val && val.length > 0 ? true : false),
      then: Yup.string().oneOf(
        [Yup.ref("newPassword")],
        "Both passwords need to be the same"
      )
    })
});

const addBeneficiarySchema = Yup.object().shape({
    country: Yup.string().required().label('Country'),
    phone: Yup.string().required().label('Mobile Number'),
    firstName: Yup.string().required().min(2).label('First Name'),
    // middleName: Yup.string().when('middleName',{
    //   is:(val)=> val.length > 0,
    //   then: Yup.string().min(2, 'Middle Name must be at least 2 characters')
    // }),
    // middleName: Yup.string().when("middleName", {
    //   is: val => (val && val.length > 0 ? true : false),
    //   then: Yup.string().min(2, 'Middle Name must be at least 2 characters')
    // }),
    middleName: Yup.string().test(
        'empty-or-2-characters-check',
        'Middle Name must be at least 2 characters',
        middleName => !middleName || middleName.length >= 2,
    ),
    lastName: Yup.string().required().min(2).label('Last Name'),
    // idType: Yup.string().required().label('ID Type'),
    // idDetails: Yup.string().required().label('ID Details')
});


const updateProfileSchema = (country) => Yup.object().shape({
  firstName: Yup.string().required().min(2).label('First Name'),
  middleName: Yup.string().test(
      'empty-or-2-characters-check',
      'Middle Name must be at least 2 characters',
      middleName => !middleName || middleName.length >= 2,
  ),
  lastName: Yup.string().required().min(2).label('Last Name'),
  birthDay: Yup.string().required(), birthMonth: Yup.string().required(), birthYear: Yup.string().required(),
  nationality: Yup.string().required().label('Nationality'),
  gender: Yup.string().required().label('Gender'),
  purpose: Yup.string().required().label('Purpose of Remittance'),
  mobile: Yup.string().required().label('Mobile Number'),
  postCode: Yup.string().required().label('Post Code'),
  addressLine1: Yup.string().required().label('Address Line 1'),
  city: Yup.string().required().label('City'),
  state: Yup.string().required().label('State/Province'),
  idType: Yup.string().required().label('ID Type'),
  idDetails: Yup.string().required().label('ID Details'),
  issuePlace: Yup.string().required().label('ID Issue Place'),
  issueCountry: Yup.string().required().label('ID Issue Country'),
  idDay: Yup.string().required(), idMonth: Yup.string().required(), idYear: Yup.string().required(),
  id1Scan1: Yup.string().required().label('ID Scan 1 (Front)'),
  // id2Scan1: Yup.string().required().label('Selfie Photo'),
  // id2Scan1: Yup.string()
  //   .when('id1Scan1', {
  //     is: country === 'South Africa',
  //     then: Yup.string()
  //       .required()
  //       // .typeError('Time field is required')
  //   })
  // id2Scan1: Yup.string().when(country, {
  //   is: country === 'South Africa' ? true : false,
  //   then: Yup.string().required()
  // }),
});

export { forgotPasswordSchema, resetPasswordSchema, changePasswordSchema, addBeneficiarySchema, updateProfileSchema }